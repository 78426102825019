import styled, { css } from "styled-components";

interface StyledFullBleedGrapicOverlayProps {
  placement?: string;
  mobile?: boolean;
}

interface StyledFullBleedVideProps {
  square?: boolean;
}

export const StyledFullBleedImgContainer = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0 ${(props) => props.theme.spacing.three} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledFullBleedImg = styled.div`
  position: relative;
  height: 100%;

  & picture {
    & img {
      height: 700px;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & picture {
      & img {
        height: 350px;
      }
    }
  }
`;

export const StyledFullBleedGrapicOverlay = styled.img<StyledFullBleedGrapicOverlayProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: contain;

  ${(props) =>
    props.placement === "top" &&
    css`
      top: -1px;
      -webkit-transform: rotate(-180deg); /* Safari */
      -moz-transform: rotate(-180deg); /* Firefox 3.6 Firefox 4 */
      -ms-transform: rotate(-180deg); /* IE9 */
      -o-transform: rotate(-180deg); /* Opera */
      transform: rotate(-180deg); /* W3C */
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.mobile ? "block" : "none")};
  }
`;
export const StyledVideoWrapper = styled.div<StyledFullBleedVideProps>`
display: flex;
justify-content: space-between;
flex-direction: row;
max-width: ${(props) => (props.square ? "960px" : "100%")};
margin: 0 auto;
height: ${(props) => (props.square ? "500px" : "700px")};

@media screen and (max-width: 767px) {
  height:210px
}
`;
export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledFullBleedTitle = styled.h2`
  font-size: 38px;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.neutrals.black};
  margin-bottom: ${({ theme }) => theme.spacing.five};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 30px;
    max-width: 100%;
    line-height: 40px;
  }
`;
export const StyledFullBleedDescription = styled.p`
margin-bottom: ${(props) => props.theme.spacing.four};
text-align: center;
& h2 {
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
}

& p {
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
}
margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 90%;
  }
`;