import styled, { keyframes, css } from "styled-components";

interface StyledModalProps {
  isOpen?: boolean;
}

interface StyledModalContentProps {
  isOverflow?: boolean;
  isHalf?: boolean;
  handleClose?: boolean;
}

const slideIn = keyframes`
  0% {
    transform: translateY(800px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
`;

export const StyledModal = styled.div<StyledModalProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);

  -webkit-transform: translate3d(0, 0, 100px);
  transform: translate3d(0, 0, 100px);
`;

export const StyledModalContent = styled.div<StyledModalContentProps>`
  margin-top: ${(props) => (props.isOverflow ? "0" : props.theme.spacing.two)};
  animation: ${slideIn} 0.5s linear;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  border-radius: ${(props) => (props.isOverflow ? "none" : "8px 8px 0 0")};
  width: 100%;
  height: 100%;
  max-height: ${({ isHalf }) => (isHalf ? "328px" : "100vh")};

  transition: max-height 0.4s linear;
  -moz-transition: max-height 0.4s linear;
  -webkit-transition: max-height 0.4s linear;
  -o-transition: max-height 0.4s linear;

  z-index: 9999;
  position: absolute;
  bottom: 0;

  ${(props) =>
    props.handleClose &&
    css`
      transition: transform 0.4s linear;
      -moz-transition: transform 0.4s linear;
      -webkit-transition: transform 0.4s linear;
      -o-transition: transform 0.4s linear;
      transform: translateY(100%);
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-height: ${({ isHalf }) => (isHalf ? "272px" : "100vh")};
  }
`;

export const StyledModalHeader = styled.div<StyledModalContentProps>`
  padding: ${(props) => props.theme.spacing.three};
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.isOverflow ? "1px solid rgba(0, 0, 0, 0.3)" : "none"};
`;

export const StyledIconContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  & svg {
    margin-right: ${(props) => props.theme.spacing.one};
  }

  & span {
    display: flex;
    align-items: center;
  }
`;

interface StyledModalBodyProps {
  header?: boolean;
}

export const StyledModalBody = styled.div<StyledModalBodyProps>`
  height: ${({ header }) => (header ? "calc(100% - 64px)" : "100%")};
  overflow: auto;
`;

export const StyledModalFooter = styled.div`
  height: 64px;
`;
