import styled from "styled-components";

interface StyledOverviewHeaderImgContainerProps {
  video?: boolean;
}

export const StyledOverviewHeader = styled.div`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three} ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledOverviewHeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing.three};

  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
    grid-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledOverviewHeaderTitle = styled.h1`
  font-size: 48px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  :lang(jp) {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;

    :lang(jp) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const StyledOverviewHeaderSubHeader = styled.p`
  white-space: pre-line;
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;
`;

export const StyledOverviewHeaderTextContainer = styled.div`
  max-width: 386px;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;

export const StyledOverviewHeaderImgContainer = styled.div<StyledOverviewHeaderImgContainerProps>`
  position: relative;
  height: 320px;
  cursor: ${(props) => (props.video ? "pointer" : "initial")};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column-start: 1;
    grid-row-start: 1;
    height: 280px;
  }
`;

export const StyledOverviewHeaderImgPlaceholder = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 4;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-height: 400px;
  }
`;

export const StyledIconPlayWrapper = styled.div`
  position: absolute;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
