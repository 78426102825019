import * as React from "react";
import { Overview, OverviewProps } from "./Overview";
import { blokType, sbLinkType, storyType } from "../../types";
import { ArticleOverviewQueryCmp } from "./ArticleOverviewQuery";
import { StyledArticleOverview, StyledOverviewWrapper } from "./partials";

export interface ArticleOverviewProps {
  blok: blokType &
    OverviewProps & {
      cards: Array<
        storyType & {
          content: string | blokType;
        }
      >;
      folder: sbLinkType;
      card_link_label?: string;
      tag?: Array<string>;
    };
}

export const ArticleOverview: React.FC<ArticleOverviewProps> = ({ blok }) => {
  const {
    title,
    body_copy,
    cta_label,
    cta_link,
    cta_colour,
    folder,
    tag,
    card_link_label,
  } = blok;

  return (
    <StyledArticleOverview>
      <StyledOverviewWrapper>
        <Overview
          title={title}
          component="article-overview"
          body_copy={body_copy}
          cta_label={cta_label}
          cta_link={cta_link}
          cta_colour={cta_colour}
        >
          <ArticleOverviewQueryCmp
            folder={folder}
            card_link_label={card_link_label}
            showPagination={!cta_label}
            tag={tag}
          />
        </Overview>
      </StyledOverviewWrapper>
    </StyledArticleOverview>
  );
};
