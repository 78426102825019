import styled from "styled-components";

interface StyledFacultyCarouselCardProps {
  clickable?: boolean;
}
export const StyledFacultyCarouselCardContent = styled.div`
  position: relative;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: var(
    0 2px 8px 0 rgba(25, 25, 25, 0.16),
    0 2px 16px 0 rgba(25, 25, 25, 0.1)
  );

  > *:last-child {
    margin-bottom: 0;
  }

  img {
    display: block;
  }
`;
export const StyledFacultyCarouselCard = styled.div<StyledFacultyCarouselCardProps>`
  height: 330px;
  position: relative;
  cursor: pointer;
  & ${StyledFacultyCarouselCardContent}{
    height: 100%;
    padding: 0;
  }
`;
export const StyledFacultyCarouselCardImg = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  background-color: #ffffff;
`;

export const StyledFacultyCarouselCardText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  border-radius: inherit;
  box-sizing: border-box;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`;

export const StyledFacultyCarouselCardTextHeadline = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.neutrals.white};
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 32px);
`;

export const StyledFacultyCarouselCardTextBottom = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.book};
    color: ${(props) => props.theme.colors.neutrals.white};
    margin: 0;
  }

  & div {
    height: 16px;
    width: 16px;
    margin-left: 16px;
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.white};
  }
`;

export const StyledLinkItem = styled.a`
& a {
  cursor: pointer;
  text-decoration: none;
}
`;