import React, { FC } from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { StatsNumberCmp, StatsNumberProps } from "./StatsNumber";
interface StatsNumberPropsWBlokProps {
  blok: StatsNumberProps & blokType;
}

export const StatisticalNumber: FC<StatsNumberPropsWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <StatsNumberCmp
        title={blok.title}
        indicators={blok.indicators} >
      </StatsNumberCmp>
    </SbEditable>
  );
};