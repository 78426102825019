import React, { useState } from "react";

import { getKey, getUrl } from "../utils/helpers";

import {
  StyledFooterLinksSection,
  StyledFooterLinksSectionIconsContainer,
  StyledFooterLinkListHeaderContainer,
  StyledFooterLinkListHeader,
  StyledFooterLinkListBreaker,
  StyledFooterLinksSectionContainer,
  StyledFooterLinkListLink,
} from "./partials";

import { Icon } from "../Icons";

export interface FooterLinkListProps {
  header: string;
  links: Array<any>;
}

export const FooterLinkList: React.FC<FooterLinkListProps> = ({
  header,
  links,
}) => {
  const [listOpen, setListOpen] = useState(false);

  function toggleOpen() {
    setListOpen(!listOpen);
  }

  return (
    <StyledFooterLinksSection
      key={getKey("footer-list-link", header)}
      isOpen={listOpen}
    >
      <StyledFooterLinkListHeaderContainer onClick={(_) => toggleOpen()}>
        <StyledFooterLinkListHeader>{header}</StyledFooterLinkListHeader>
        <StyledFooterLinksSectionIconsContainer isOpen={listOpen}>
          <Icon icon="chevron-down" />
        </StyledFooterLinksSectionIconsContainer>
      </StyledFooterLinkListHeaderContainer>

      <StyledFooterLinkListBreaker />
      <StyledFooterLinksSectionContainer isOpen={listOpen}>
        {links.map((item, idx) => (
          <StyledFooterLinkListLink
            key={getKey("footer-list-link", idx)}
            href={getUrl(item.link)}
          >
            {item.name}
          </StyledFooterLinkListLink>
        ))}
      </StyledFooterLinksSectionContainer>
    </StyledFooterLinksSection>
  );
};
