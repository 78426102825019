import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { QuoteCmp, QuoteProps } from "./Quote";

interface QuoteWBlokProps {
  blok: QuoteProps & blokType;
}

export const Quote: React.FC<QuoteWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <QuoteCmp
        quote={blok.quote}
        author={blok.author}
        author_picture={blok.author_picture}
        company_logo={blok.company_logo}
      ></QuoteCmp>
    </SbEditable>
  );
};
