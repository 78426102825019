import React from "react";
import { sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import {
  StyledSNumbIndicator,
  StyledSNumbIndicatorContainer,
  StyledSNumbIndicatorText,
  StyledSNumbIndicatorImg,
  StyledHeaderAssetWrapper,
} from "./Partials";

export interface StatsNumberIndicatorProps {
  image: sbAssetType;
  number: number;
  quantity?: string;
  description?: string;
}

export const StatsNumberIndicator: React.FC<StatsNumberIndicatorProps> = ({
  image, number, quantity, description }) => {
  return (
    <StyledSNumbIndicator>
      
      <StyledHeaderAssetWrapper>
        <StyledSNumbIndicatorImg
          src={sbImageService(image.filename, undefined, "310x0", image.focus)}
          alt={image.alt}
          title={image.title}
          loading="lazy"
        />
      </StyledHeaderAssetWrapper>
     
      <StyledSNumbIndicatorContainer>
        <CountUp
          start={0}
          end={number ?? 0}
          duration={5}
          prefix="+"
          suffix={`${quantity ?? ""}`}
        >
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start}>
              <span ref={countUpRef} />
            </VisibilitySensor>
          )}
        </CountUp>
        {description && <StyledSNumbIndicatorText>{description}</StyledSNumbIndicatorText>}
      </StyledSNumbIndicatorContainer>

    </StyledSNumbIndicator>
  );
};
