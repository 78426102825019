import styled from "styled-components";

interface StyledFooterLinksSectionProps {
  isOpen?: boolean;
}

export const StyledFooterLinksSection = styled.div<StyledFooterLinksSectionProps>`
  height: auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 100%;
    border-bottom: 1px solid rgba(25, 25, 25, 0.2);
    overflow: hidden;
  }
`;

export const StyledFooterLinksSectionIconsContainer = styled.div<StyledFooterLinksSectionProps>`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;

    & svg {
      transition: transform 0.5s linear;
      -moz-transition: transform 0.5s linear;
      -webkit-transition: transform 0.5s linear;
      -o-transition: transform 0.5s linear;
      transform-origin: center;
      transform: ${(props) =>
        props.isOpen ? "rotate(180deg)" : "rotate(0deg)"};
    }
  }
`;

export const StyledFooterLinkListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    cursor: pointer;
  }
`;

export const StyledFooterLinkListHeader = styled.p`
  margin-bottom: ${(props) => props.theme.spacing.two};
  font-size: 12px;
  font-weight: ${(props) => props.theme.font.bold};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 16px;
  }
`;

export const StyledFooterLinkListBreaker = styled.hr`
  margin-bottom: ${(props) => props.theme.spacing.two};
  background-color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledFooterLinksSectionContainer = styled.div<StyledFooterLinksSectionProps>`
  transition: max-height 0.5s ease-out, padding 0.6s ease-out;
  -moz-transition: max-height 0.5s ease-out, padding 0.6s ease-out;
  -webkit-transition: max-height 0.5s ease-out, padding 0.8s ease-out;
  -o-transition: max-height 0.5s ease-out, padding 0.8s ease-out;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    overflow: hidden;
    max-height: ${(props) => (props.isOpen ? "600px" : "0px")};
    display: grid;
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.spacing.three};

    padding-top: ${(props) => (props.isOpen ? props.theme.spacing.one : "0")};
    padding-bottom: ${(props) =>
      props.isOpen ? props.theme.spacing.six : "0"};
  }
`;

export const StyledFooterLinkListLink = styled.a`
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 22px;
  font-weight: ${(props) => props.theme.font.book};
  cursor: pointer;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 14px;
    width: auto;
  }
`;
