import styled from "styled-components";

interface StyledCompetitionTimelineProps {
  number?: number;
}

export const StyledCompetitionTimeline = styled.div`
  overflow: hidden;
  padding: ${(props) => props.theme.spacing.eight} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledTitleWrapper = styled.div`
  width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three};
  }
`;

export const StyledTitle = styled.h1``;

export const StyledSubTitle = styled.h2`
  color: ${(props) => props.theme.colors.warmths.pink};
`;

export const StyledSteps = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}) {
    justify-content: center;
  }

  @media screen and (max-width: 1488px) {
    justify-content: flex-start;
  }
`;

export const StyledStep = styled.div<StyledCompetitionTimelineProps>`
  display: flex;
  scroll-snap-align: start;
  flex-shrink: 0;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  width: 300px;

  ${(props) =>
    props.number === 5 &&
    `
    & svg {
        color: #FC8829;
    } 
    `}

  ${(props) =>
    props.number === 4 &&
    `
    margin-top: 30.5px;
    margin-right: -1px;
    
    & svg {
        color: #EF4563;
    }
    `}
    
    ${(props) =>
    props.number === 3 &&
    `
    margin-top: 61px;
    margin-right: -1px;
    
    & svg {
        color: #e6007e;
    }
    `}

    ${(props) =>
    props.number === 2 &&
    `
    margin-top: 91.5px;
    margin-right: -1px;
    
    & svg {
        color: #c50970;
    }
    `}

    ${(props) =>
    props.number === 1 &&
    `
    margin-top: 122px;
    margin-right: -1px;
    
    & svg {
        color: #a0187c;
    }
    `}
`;
