import styled from "styled-components";

interface StyledLogosWrapperProps {
  addWrapper?: boolean;
}

export const StyledClientLogos = styled.div`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledClientLogosWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledClientLogosHeader = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledClientLogosSubheader = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
`;

// OLD React-Slick styling
export const StyledClientLogosCardsContainer = styled.div<StyledLogosWrapperProps>`
  width: 100%;
  margin: ${(props) =>
    props.addWrapper
      ? `${props.theme.spacing.four} auto ${props.theme.spacing.one} auto`
      : `${props.theme.spacing.four} 0 ${props.theme.spacing.one} 0`};
  max-width: ${(props) =>
    props.addWrapper ? `${props.theme.maxWidth}` : `initial`};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & .slick-list {
      height: 163px;
    }
  }
`;

export const StyledClientLogosCardsWrapper = styled.div<StyledLogosWrapperProps>`
  width: 100%;
  margin: ${(props) =>
    props.addWrapper
      ? `${props.theme.spacing.four} auto ${props.theme.spacing.four} auto`
      : `${props.theme.spacing.four} 0 ${props.theme.spacing.four} 0`};
  max-width: ${(props) =>
    props.addWrapper ? `${props.theme.maxWidth}` : `initial`};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  // Use below for animations
  // grid-auto-flow: column;
  row-gap: ${(props) => props.theme.spacing.four};
  column-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: ${(props) => props.theme.spacing.two};
    column-gap: ${(props) => props.theme.spacing.two};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr 1fr;
  }
`;
