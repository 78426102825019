import styled from "styled-components";

interface StyledLinkItemProps {
  spaceApart?: boolean;
}

export const StyledLinkItem = styled.a<StyledLinkItemProps>`
  width: ${(props) => (props.spaceApart ? "100%" : "auto")};
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) =>
    props.spaceApart ? "space-between" : "initial"};
  cursor: pointer;
  font-weight: ${(props) => props.theme.font.book};
  color: ${(props) => props.theme.colors.neutrals.black};
  text-decoration: none;

  & svg {
    margin-left: ${(props) => props.theme.spacing.two};
    transition: transform 500ms;
  }

  :hover {
    & svg {
      transform: translateX(16px);
    }
    text-decoration: underline;
  }
`;
