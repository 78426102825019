import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { BodyCopyCmp, BodyCopyProps } from "./BodyCopy";

interface BodyCopyWBlokProps {
  blok: BodyCopyProps & blokType;
}

export const BodyCopy: React.FC<BodyCopyWBlokProps> = ({ blok }) => (
  <SbEditable content={blok}>
    <BodyCopyCmp
      title={blok.title}
      component={blok.component}
      body={blok.body}
      position={blok.position}
      brand_colour={blok.brand_colour}
    ></BodyCopyCmp>
  </SbEditable>
);
