import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { DescriptionWithImageCmp, DescriptionWithImageProps } from "../DescriptionWithimage/DescriptionWithImage";

interface ExpertWithLinkWBlokProps {
  blok: DescriptionWithImageProps & blokType;
}

export const ExpertsWithLinks: React.FC<ExpertWithLinkWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <DescriptionWithImageCmp
        title={blok.title}
        component={blok.component}
        body={blok.body}
        cards={blok.cards}
      ></DescriptionWithImageCmp>
    </SbEditable>
  );
};
