import React from "react";
import { brandColors } from "../../types";

import {
  StyledHighlightedList,
  StyledHighlightedListWrapper,
  StyledHighlightedListContent,
  StyledHighlightedListContentText,
  StyledHighlightedListContentQual,
  StyledHighlightedListContentPoint,
} from "./partials";

export interface HighlightedPointProps {
  point: string;
}

export interface HighlightedListProps {
  qualifying_title: string;
  highlighted_points: Array<HighlightedPointProps>;
  background_colour?: brandColors;
  component?: string;
}

export const HighlightedListCmp: React.FC<HighlightedListProps> = ({
  qualifying_title,
  highlighted_points,
  background_colour = "magenta",
  component
}) => {
  return (
    <StyledHighlightedListWrapper id={component}>
      <StyledHighlightedList background_color={background_colour}>
        <StyledHighlightedListContent>
          <StyledHighlightedListContentText>
            <StyledHighlightedListContentQual>
              {qualifying_title}
            </StyledHighlightedListContentQual>
            {highlighted_points.map((item, idx) => (
              <StyledHighlightedListContentPoint
                key={`highlighted-point-${idx}`}
              >
                <p>{item.point}</p>
                {highlighted_points.length > 1 &&
                  highlighted_points.length - 1 !== idx && (
                    <hr key={`highlighted-point-hr-${idx}`}></hr>
                  )}
              </StyledHighlightedListContentPoint>
            ))}
          </StyledHighlightedListContentText>
        </StyledHighlightedListContent>
      </StyledHighlightedList>
    </StyledHighlightedListWrapper>
  );
};
