import styled from "styled-components"

export const StyledFormSpinner = styled.div`
  top: 0px;
  left: 0px;
  position: fixed;
  text-align: center;
  background-color: #000; 
  width: 100%;
  height: 100%;
  z-index: 1051;
  opacity: 0.5; 
 
  .loader-container, .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
  }

  .loader-container, .spinner, .path{
    stroke: #e6007f;
    stroke-linecap: round;
    animation: dash 1.5s linear infinite;
  }

  @keyframes rotate{
    100%{
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash{
    0%{
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50%{
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100%{
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;