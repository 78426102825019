import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { ClientLogosCmp, ClientLogosProps } from "./ClientLogos";

interface ClientLogosWBlokProps {
  blok: ClientLogosProps & blokType;
}

export const ClientLogos: React.FC<ClientLogosWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ClientLogosCmp
        title={blok.title}
        sub_header={blok.sub_header}
        link={blok.link}
        client_cards={blok.client_cards}
        component={blok.component}
      ></ClientLogosCmp>
    </SbEditable>
  );
};
