import styled from "styled-components";

export const StyledCookie = styled.div`
  position: fixed;
  bottom: 8px;
  left: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(25, 25, 25, 0.16);
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
  max-width: 327px;
  margin-right: 8px;
`;

export const StyledCookieHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    font-weight: ${({ theme }) => theme.font.bold};
    font-size: 14px;
    margin: 0;
  }

  & svg {
    cursor: pointer;
  }

  margin-bottom: ${({ theme }) => theme.spacing.one};
`;

export const StyledCookieBody = styled.div`
  & p {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.font.book};
    margin-bottom: 0;
  }

  & a {
    color: ${({ theme }) => theme.colors.warmths.magenta};
  }
`;
