import React, { useState, useRef } from "react";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

import {
  StyledFacultyCarouselModal,
  StyledFacultyCarouselModalWrapper,
  StyledFacultyCarouselModalPaginationContainer,
  StyledFacultyCarouselModalControlsItem,
  StyledFacultyCarouselModalSwiperContainer,
  StyledFacultyCarouselModalContentContainer,
  StyledFacultyCarouselModalContentImg,
  StyledFacultyCarouselModalTextContent,
  StyledFacultyCarouselModalTextTitle,
  StyledFacultyCarouselModalTextBody,
  StyledFacultyCarouselModalTextSubHeader,
} from "./partials";
import { FacultyCarouselCardProps } from "./FacultyCarouselCard";
import { sbImageService } from "hult-sb-ui";
import { Icon } from "../Icons";

export interface FacultyCarouselModalProps {
  cards: Array<FacultyCarouselCardProps>;
  initialSlide?: number;
  show_qualifier_modal?: boolean;
}

export const FacultyCarouselModal: React.FC<FacultyCarouselModalProps> = ({
  cards,
  initialSlide = 0,
  show_qualifier_modal = false,
}) => {
  const [current, setCurrent] = useState(0);
  const swiperRef = useRef(null);

  return (
    <StyledFacultyCarouselModal>
      <StyledFacultyCarouselModalWrapper>
        <StyledFacultyCarouselModalSwiperContainer>
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={24}
            onSlideChange={(swiper) => setCurrent(swiper?.snapIndex)}
            initialSlide={initialSlide}
          >
            {cards.map((item, idx) => (
              <SwiperSlide key={`swiper-slide-${idx}`}>
                <div>
                  <StyledFacultyCarouselModalContentContainer>
                    <StyledFacultyCarouselModalContentImg
                      src={sbImageService(
                        item.image.filename,
                        undefined,
                        "0x330",
                        item.image.focus
                      )}
                      alt={item.image.alt}
                      title={item.image?.title}
                      loading="lazy"
                    />
                    <StyledFacultyCarouselModalTextContent>
                      <StyledFacultyCarouselModalTextTitle>
                        {item.title}
                      </StyledFacultyCarouselModalTextTitle>
                      {show_qualifier_modal && (
                        <StyledFacultyCarouselModalTextSubHeader>
                          {item.qualifier}
                        </StyledFacultyCarouselModalTextSubHeader>
                      )}

                      <StyledFacultyCarouselModalTextBody>
                        {item.body_copy}
                      </StyledFacultyCarouselModalTextBody>
                    </StyledFacultyCarouselModalTextContent>
                  </StyledFacultyCarouselModalContentContainer>
                  {cards.length > 1 && (
                    <StyledFacultyCarouselModalPaginationContainer>
                      {idx != 0 && (
                        <StyledFacultyCarouselModalControlsItem
                          onClick={() => swiperRef?.current?.swiper.slidePrev()}
                        >
                          <Icon icon="arrow-left" />

                          <span>{cards[idx - 1].title}</span>
                        </StyledFacultyCarouselModalControlsItem>
                      )}
                      {idx !== cards.length - 1 && (
                        <StyledFacultyCarouselModalControlsItem
                          right
                          onClick={() => swiperRef?.current?.swiper.slideNext()}
                        >
                          <span>{cards[idx + 1].title}</span>
                          <Icon icon="arrow-right" />
                        </StyledFacultyCarouselModalControlsItem>
                      )}
                    </StyledFacultyCarouselModalPaginationContainer>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledFacultyCarouselModalSwiperContainer>
      </StyledFacultyCarouselModalWrapper>
    </StyledFacultyCarouselModal>
  );
};
