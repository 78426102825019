import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { HighlightedListCmp, HighlightedListProps } from "./HighlightedList";

interface HighlightedListWBlokProps {
  blok: HighlightedListProps & blokType;
}

export const HighlightedList: React.FC<HighlightedListWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <HighlightedListCmp
        key={blok._uid}
        component={blok.component}
        qualifying_title={blok.qualifying_title}
        highlighted_points={blok.highlighted_points}
      ></HighlightedListCmp>
    </SbEditable>
  );
};
