import React from "react";

import {
  StyledEmbeddedForm,
  StyledEmbeddedFormTitle,
  StyledEmbeddedFormContent,
} from "./partials";

export interface EmbeddedFormProps {
  title?: string;
  body: string;
}

export const EmbeddedFormCmp: React.FC<EmbeddedFormProps> = ({
  title,
  body,
}) => {

  function toHtml(body: string) {
    return <div dangerouslySetInnerHTML={{ __html: body }} />;
  }
  return (
    <StyledEmbeddedForm id={title?.toLowerCase().replace(/ /g, "-")}>
      {title && <StyledEmbeddedFormTitle>{title}</StyledEmbeddedFormTitle>}
      <StyledEmbeddedFormContent>
        {toHtml(body)}
      </StyledEmbeddedFormContent>
    </StyledEmbeddedForm>
  );
};

