import styled from "styled-components";

export const StyledSocialBar = styled.div`
  display: flex;
  justify-content: left;
  padding-bottom: 10px;
`;

export const StyledLinkSocialItem = styled.a`
  padding-right: 20px;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;

  :hover {
    color: #E6007F;
  }
`;
