import React from "react";
import ReactPlayer from "react-player";
import { sbAssetType } from "../../types";
import { ButtonHEF } from "../Button";
import { brandPink } from "../../types";

import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledHeaderContentWrapper,
  StyledHeaderDescription,
  StyledHeaderAssetWrapper,
  StyledIconPlayWrapper,
  StyledBackground,
  StyledDescriptionAssetWrapper
} from "./partials";

import { LinkItem, LinkItemProps } from "../LinkItem";

import { SbImage, sbImageService } from "hult-sb-ui";
import { Icon } from "../Icons";
import { SbImageBackgroundService } from "../utils/helpers";

export interface HeaderProps {
  title: string | any;
  description: string;
  component?: string;
  image?: sbAssetType;
  video?: sbAssetType;
  video_preview?: sbAssetType;
  asset_link?: string;
  link?: Array<LinkItemProps>;
  alternative_variant?: boolean;
  enable_cta?: boolean;
  background_colour?: brandPink;
  background_image?: sbAssetType;
  image_description?: sbAssetType;
  body_variant?: boolean;
  loading?: "lazy" | "eager";
}

const reactPlayerInline = {
  borderRadius: "4px",
};

export const HeaderCmp: React.FC<HeaderProps> = ({
  title,
  description,
  component,
  link,
  image,
  video,
  video_preview,
  asset_link,
  background_image,
  image_description,
  alternative_variant = false,
  enable_cta = false,
  background_colour = "pink",
  body_variant = false,
  loading = "eager",
}) => {
  return (
    <StyledHeader
      reverse={alternative_variant}
      id={title.toLowerCase().replace(/ /g, '-')}
      baImage={background_image?.filename}
    >
      {SbImageBackgroundService(background_image && background_image?.filename) &&
        <StyledBackground style={{
          backgroundImage: `url("${SbImageBackgroundService(background_image && background_image?.filename)}")`,
        }}></StyledBackground>
      }
      <StyledHeaderWrapper
        reverse={alternative_variant}
        baImage={background_image?.filename}
      >
        <StyledHeaderContentWrapper reverse={alternative_variant}>
          {!body_variant && <h1>{title}</h1>}
          {body_variant && <h2>{title}</h2>}
          <StyledHeaderDescription>{description}</StyledHeaderDescription>
          <StyledDescriptionAssetWrapper>
           {image_description?.filename && <SbImage image={image_description} loading={loading} />}
          </StyledDescriptionAssetWrapper>
          {Array.isArray(link) && link.length === 1 && enable_cta == true ?
            <ButtonHEF
              as={"a"}
              variant="primary"
              parentComponent="description-with-image-card"
              href={link[0].link}
              label={link[0].name}
              color={background_colour}
            ></ButtonHEF>
            :
            Array.isArray(link) && link.length === 1 && (
              <LinkItem
                link={link[0].link}
                name={link[0].name}
                parentComponent="description-with-image-card"
              />
            )
          }
        </StyledHeaderContentWrapper>
        <StyledHeaderAssetWrapper reverse={alternative_variant} baImage={background_image?.filename}>
          {image?.filename && <SbImage image={image} loading={loading} />}
          {/* If image available only show image */}
          {video?.filename && !image?.filename && video_preview?.filename && (
            <ReactPlayer
              url={video.filename}
              light={sbImageService(video_preview.filename)}
              width="100%"
              height="263px"
              playing={true}
              style={reactPlayerInline}
              playIcon={
                <StyledIconPlayWrapper>
                  <Icon icon="play" />
                </StyledIconPlayWrapper>
              }
              controls={true}
            />
          )}
          {/* If image or video available dont show iframe */}
          {asset_link && (!image?.filename || !video?.filename) && (
            <ReactPlayer
              url={asset_link}
              light
              width="100%"
              height="263px"
              style={reactPlayerInline}
              playIcon={
                <StyledIconPlayWrapper>
                  <Icon icon="play" />
                </StyledIconPlayWrapper>
              }
              playing={true}
              controls={true}
            />
          )}
        </StyledHeaderAssetWrapper>
      </StyledHeaderWrapper>
    </StyledHeader>
  );
};
