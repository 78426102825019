import React from "react";

import { sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";

import {
  StyledOverviewHeader,
  StyledOverviewHeaderContent,
  StyledOverviewHeaderTitle,
  StyledOverviewHeaderSubHeader,
  StyledOverviewHeaderTextContainer,
  StyledOverviewHeaderImgContainer,
  StyledOverviewHeaderImgPlaceholder,
} from "./partials";

export interface OverviewHeaderProps {
  title: string;
  sub_header: string;
  image?: sbAssetType;
}

export const OverviewHeaderCmp: React.FC<OverviewHeaderProps> = ({
  title,
  sub_header,
  image,
}) => {
  return (
    <>
      <StyledOverviewHeader id={title.toLowerCase().replace(/ /g, '-')}>
        <StyledOverviewHeaderContent>
          <StyledOverviewHeaderTextContainer>
            <div>
              <StyledOverviewHeaderTitle>{title}</StyledOverviewHeaderTitle>
              <StyledOverviewHeaderSubHeader>
                {sub_header}
              </StyledOverviewHeaderSubHeader>
            </div>
          </StyledOverviewHeaderTextContainer>
          {image && (
            <StyledOverviewHeaderImgContainer>
              <StyledOverviewHeaderImgPlaceholder
                src={sbImageService(
                  image.filename,
                  undefined,
                  "0x400",
                  image.focus
                )}
                alt={image?.alt}
                title={image?.title}
              />
            </StyledOverviewHeaderImgContainer>
          )}
        </StyledOverviewHeaderContent>
      </StyledOverviewHeader>
    </>
  );
};
