import React, { useEffect, useState } from "react";
import { StyledInput, StyledRequired, StyledInvalidData } from "./Partials";

interface TextDateProps {
  name: string;
  type: string;
  _uid: string;
  defaultValue: any;
  required: boolean;
  changeOptions: Function;
  isValid: boolean;
  maxLength?: number;
}

export const Url: React.FC<TextDateProps> = ({
  name,
  type,
  defaultValue,
  required,
  _uid,
  changeOptions,
  isValid,
  maxLength,
}) => {
  let pattern =
    "^(https:?//(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})*$";

  return (
    <>
      {isValid === false &&
        (defaultValue.length > 0 ? (
          <StyledInvalidData>Invalid data entry</StyledInvalidData>
        ) : (
          <StyledRequired>This is required</StyledRequired>
        ))}
      {maxLength && defaultValue.length >= maxLength && (
        <StyledInvalidData>The max length is {maxLength} characters</StyledInvalidData>
      )}
      <StyledInput
        defaultValue={defaultValue}
        id={_uid}
        pattern={pattern}
        name={name}
        type={type}
        onChange={($event: any) => {
          const {
            validity: { valid },
          } = $event.target;
          // Check if maxLength condition is true and set valid to false
          const isMaxLengthExceeded = maxLength && $event.target.value.length >= maxLength;
          const updatedValid = isMaxLengthExceeded ? false : valid;

          changeOptions($event, name, _uid, type, updatedValid);
        }}
        required={required}
      />
    </>
  );
};
