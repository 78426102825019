import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { ICDFormWrapperBlok, FormWrapperCmp } from "./FormWrapper";

interface FormWrapWBlokProps {
  blok: ICDFormWrapperBlok & blokType;
}

export const FormWrapper: React.FC<FormWrapWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FormWrapperCmp blok={blok}></FormWrapperCmp>
    </SbEditable>
  );
};