import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

import {
  StyledBodyCopy,
  StyledBodyCopyWrapper,
  StyledBodyCopyTitle,
  StyledBodyCopyContent,
} from "./partials";
import { brandColors } from "../../types";

export interface BodyCopyProps {
  title: string;
  component: string;
  body: any;
  position: "left" | "center";
  brand_colour?: brandColors;
}

export const BodyCopyCmp: React.FC<BodyCopyProps> = ({
  title,
  component,
  body,
  position,
  brand_colour,
}) => (
  <StyledBodyCopy id={title.toLowerCase().replace(/ /g, "-")}>
    <StyledBodyCopyWrapper position={position}>
      {title && <StyledBodyCopyTitle>{title}</StyledBodyCopyTitle>}
      <StyledBodyCopyContent position={position} color={brand_colour}>
        {render(body)}
      </StyledBodyCopyContent>
    </StyledBodyCopyWrapper>
  </StyledBodyCopy>
);
