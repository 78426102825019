import React, { useContext } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import {
  StyledFooter,
  StyledFooterContentContainer,
  StyledFooterLogo,
  StyledFooterLogoContainer,
  StyledFooterLinksContainer,
  StyledFooterBottomBreaker,
  StyledFooterBottom,
  StyledFooterCopright,
  StyledSocialBar,
  StyledLinkSocialItem,
} from "./partials";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";

import { FooterLinkList, FooterLinkListProps } from "./FooterLinkList";
import { getKey, getUrl } from "../utils/helpers";

import { sbImageService } from "hult-sb-ui";

import { blokType, sbAssetType, sbLinkType, sbRichText } from "../../types";
import { Icon } from "../Icons";

export interface IFooter extends blokType {
  logo: sbAssetType;
  ef_logo: sbAssetType;
  hult_logo: sbAssetType;
  ef_logo_link: sbLinkType;
  hult_logo_link: sbLinkType;
  link_list: Array<FooterLinkListProps>;
  social_bar: Array<any>;
  copyright: sbRichText;
}

export const Footer: React.FC = ({}) => {
  const { settings } = useContext(SettingsContextHooks);
  const footer = settings?.content?.footer;

  return (
    <>
      {Array.isArray(footer) && footer.length > 0 && (
        <StyledFooter>
          <StyledFooterContentContainer>
            <StyledFooterLogoContainer>
              <StyledFooterLogo
                src={sbImageService(footer[0].logo.filename, 100, "180x0")}
                alt={footer[0].logo.alt}
                title={footer[0].logo.title}
                loading="lazy"
                width="180"
                height="40"
              ></StyledFooterLogo>
            </StyledFooterLogoContainer>
            <StyledFooterLinksContainer>
              {footer[0].link_list.map((list, idx: number) => (
                <FooterLinkList
                  key={getKey("footer-link-list-", idx)}
                  links={list.links}
                  header={list.header}
                />
              ))}
            </StyledFooterLinksContainer>
            <StyledSocialBar>
              {footer[0].social_bar.map((sIcon, idx: number) => (
                <StyledLinkSocialItem
                  key={getKey("footer-social-bar-item-", idx)}
                  href={getUrl(sIcon.link)}
                >
                  <Icon lib="fab" icon={sIcon.icon} />
                </StyledLinkSocialItem>
              ))}
            </StyledSocialBar>
            <StyledFooterBottomBreaker />
            {footer[0].copyright && (
              <StyledFooterBottom>
                <StyledFooterCopright>
                  <>{render(footer[0].copyright)}</>
                </StyledFooterCopright>
              </StyledFooterBottom>
            )}
          </StyledFooterContentContainer>
        </StyledFooter>
      )}
    </>
  );
};
