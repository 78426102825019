import styled from "styled-components";

export const StyleFormFieldsInput = styled.input`
    width:50%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        width: auto !important;
    }
`;

export const StyleFormFieldsSelect = styled.select`
    width:54% !important;

     @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        width:100% !important;
    }
`;

export const StyleFormFieldsTextarea = styled.textarea`
    border: 2px solid #a6a6a6;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 18px;
    width:50%;
    height: 100px;

     @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        width:auto !important;
    }
`;

export const StyledContainerRadio = styled.div`
    margin:0 !important;
    flex-direction: row !important;
    margin-top: 5px !important;

    & input {
        padding: 0 !important;
        margin: 0 5px 0 0 !important;
    }

    & input[type='radio'], input[type='checkbox'] {
        accent-color: #e6007f;
        width:auto !important;

        @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
            width:auto !important;
        }
    }
`;

export const StyledContainerCheckbox = styled.div`
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
    label {
        position: relative;
        min-height: 27px;
        display: block;
        padding-left: 30px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin: 4px;
            width: 15px;
            height: 15px;
            transition: transform 0.28s ease;
            border-radius: 0px;
            border: 2px solid #a6a6a6;
        }
        &:after {
            content: '';
            display: block;
            width: 9px;
            height: 4px;
            border-bottom: 2px solid #e6007f;
            border-left: 2px solid #e6007f;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            position: absolute;
            top: 8px;
            left: 8px;
        }
    }
    input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked ~ label{
            &:before {
                border: 2px solid #a6a6a6;
            }
            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }
        &:focus + label::before {
            outline: 0;
        }
    }

`;

export const StyledOptionsFields = styled.div`
    margin:0px 15px !important;
`;

export const StyledHelpTextFields = styled.span`
    font-style: italic;
    padding: 0 0 5px 18px;
`;

export const StyledHelpTextFieldsCustom = styled.span`
    font-weight: bold;
    padding: 10px 0 10px 0px;
`;

export const StyledOtherFields = styled.div`
    margin: initial !important;
    margin-top: 10px !important;
`;