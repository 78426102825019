import styled from "styled-components";


export const StyledGlobalNavigation = styled.header`
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  height: auto;
  width: calc(100% - 48px);
  padding: 0px ${({ theme }) => theme.spacing.three};
`;

export const StyledGlobalNavigationInner = styled.nav`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 !important;
`;

export const StyledHeaderLogo = styled.a`
  position: relative;
  height: auto;
  width: auto;
  max-width: 150px;

  & img {
    height: 32px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & img {
      height: 24px;
    }
  }
`;

export const StyledHeaderMenu = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
`;
export const StyledHeaderMenuInner = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
`;
export const StyledHeaderDropdown = styled.select`
  display: block;
  position: absolute;
  right: 20px;
  top: 40px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @media (max-width: 768px) {
    right: 125px;
  }
`;
