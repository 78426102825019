import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { Form } from "./Form";
import { render } from "storyblok-rich-text-react-renderer";
import { StyledFormWrapper, StyledForm, StyledFormWrapperTitle, StyledFormWrapperContainer } from "./Partials";

export interface ICDFormWrapperBlok {
  title: string,
  description: any,
  form: string;
}

interface ICDFormWrapper {
  blok: ICDFormWrapperBlok & blokType;
}

export const FormWrapperCmp: React.FC<ICDFormWrapper> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StyledFormWrapper>
        <StyledFormWrapperContainer>
          <StyledFormWrapperTitle>
            {blok.title}
          </StyledFormWrapperTitle>
          {blok.description && (
            render(blok.description)
          )}
          {blok.form && (
            <StyledForm>{<Form formUuid={blok.form} />}</StyledForm>
          )}
        </StyledFormWrapperContainer>
      </StyledFormWrapper>
    </SbEditable>
  );
};