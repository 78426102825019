import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Tel } from "./Tel";
import { FormFields, optionSelect } from "../FormWrapper/FormCD";

interface edges {
  node: { data_source: string; id: string; name: string; value: string };
}

interface TelephoneDropdownProps {
  field: FormFields;
  changeOptions: Function;
}

export const TelephoneDropdown: React.FC<TelephoneDropdownProps> =
  ({ field, changeOptions }) => {
    const { defaultValue, name, required, type, _uid, label, isValid } = field;
    return (
      <StaticQuery
        query={graphql`
        query {
          allStoryblokDatasourceEntry(
            filter: { data_source: { regex: "/^country-code/" } }
          ) {
            edges {
              node {
                id
                name
                value
                data_source
              }
            }
          }
        }
      `}
        render={(data) => {
          let countryCode: any;

          if (data && data.allStoryblokDatasourceEntry) {

            const { allStoryblokDatasourceEntry } = data;
            const { edges } = allStoryblokDatasourceEntry;

            let codes_countries: optionSelect[] = edges.map(
              ({ node: { id, name, value } }: edges) => ({
                label: `(+${value}) ${name}`,
                value: `+${value}`,
                id,
              })
            );

            codes_countries.unshift({
              value: "",
              label: `${label}...`,
              _uid: `option-none-${name}`,
              component: `option-none-${name}`,
              defaultValue: "",
              id: 0,
            });

            countryCode =
              <Tel
                defaultValue={defaultValue}
                name={name}
                required={required}
                type={type}
                _uid={_uid}
                changeOptions={changeOptions}
                options={codes_countries}
                isValid={isValid}
              ></Tel>;
          }
          return countryCode;
        }}
      />
    );
  };