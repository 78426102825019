import styled from "styled-components";

export const StyledCheckboxWrapper = styled.div`
   width: 100%;
`;

export const StyledCheckbox = styled.div`
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
    label {
        position: relative;
        min-height: 27px;
        display: block;
        padding-left: 30px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        span {
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin: 4px;
            width: 15px;
            height: 15px;
            transition: transform 0.28s ease;
            border-radius: 0px;
            border: 2px solid #a6a6a6;
        }
        &:after {
            content: '';
            display: block;
            width: 9px;
            height: 4px;
            border-bottom: 2px solid #e6007f;
            border-left: 2px solid #e6007f;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            position: absolute;
            top: 8px;
            left: 8px;
        }
    }
    input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked ~ label{
            &:before {
                border: 2px solid #a6a6a6;
            }
            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }
        &:focus + label::before {
            outline: 0;
        }
    }

`;