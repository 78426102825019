import React from "react";
import { BaseComponentProps } from "../../types/index";

const ComponentNotFound: React.FC<BaseComponentProps> = ({ blok }) => {
  return (
    <div>
      Component {blok.component} is not defined. Add it to components list.
    </div>
  );
};

export default ComponentNotFound;
