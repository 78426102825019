import React, { useState, useRef, useEffect } from "react";

import {
  StyledBulletPointsWrapper,
  StyledBulletPointsHeader,
  StyledBulletPointsSubHeader,
  StyledBulletPointsListContainer,
  StyledBulletPointsListMore,
} from "./partials";

import { Icon } from "../Icons";

import { BulletPointsItem, BulletPointsItemProps } from "./BulletPointsItem";
import { getKey } from "../utils/helpers";

export interface BulletPointsProps {
  title: string;
  sub_header: string;
  bullets: Array<BulletPointsItemProps>;
  show_more_label?: string;
  show_less_label?: string;
  show_more_number?: number;
  component?: string;
}

export const BulletPointsCmp: React.FC<BulletPointsProps> = ({
  title,
  sub_header,
  bullets,
  show_more_label,
  show_less_label,
  show_more_number = 7,
  component
}) => {
  const hiddenPoints = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState<undefined | number>(undefined);

  const [hiddenMaxHeight, setHiddenMaxHeight] = useState("1000px");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // timeoutId for debounce mechanism
      let timeoutId: any = null;
      const resizeListener = () => {
        // Get current height of the hidden bullet points container
        if (hiddenPoints) {
          setHiddenMaxHeight(
            `${(hiddenPoints?.current?.scrollHeight || 0) + 32}px`
          );
        }
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWindowSize(window.innerWidth), 150);
      };
      // set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, []);

  const more_label = show_more_label ? show_more_label : "Show more";
  const less_label = show_less_label ? show_less_label : "Show less";

  const shownBulletsList =
    bullets.length > show_more_number
      ? bullets.slice(0, show_more_number - 1)
      : bullets;
  const hiddenBulletsList =
    bullets.length > show_more_number
      ? bullets.slice(show_more_number - 1, bullets.length)
      : [];

  return (
    <StyledBulletPointsWrapper id={component}>
      <StyledBulletPointsHeader>{title}</StyledBulletPointsHeader>
      <StyledBulletPointsSubHeader>{sub_header}</StyledBulletPointsSubHeader>
      <StyledBulletPointsListContainer
        isOpen={true}
        hiddenDiv={bullets.length < show_more_number}
      >
        {shownBulletsList.map((bullet, idx) => (
          <BulletPointsItem
            key={getKey(
              "bullet-point-list-item-",
              bullet._uid ? bullet._uid : idx
            )}
            title={bullet.title}
            description={bullet.description}
            bullet_colour={bullet.bullet_colour}
          />
        ))}
      </StyledBulletPointsListContainer>
      {hiddenBulletsList.length > 0 && (
        <StyledBulletPointsListContainer
          isOpen={isOpen}
          ref={hiddenPoints}
          maxHeight={hiddenMaxHeight}
        >
          {hiddenBulletsList.map((bullet, idx) => (
            <BulletPointsItem
              key={getKey(
                "bullet-point-list-item-",
                bullet._uid ? bullet._uid : idx
              )}
              title={bullet.title}
              description={bullet.description}
              bullet_colour={bullet.bullet_colour}
            />
          ))}
        </StyledBulletPointsListContainer>
      )}

      {bullets.length > show_more_number && (
        <StyledBulletPointsListMore
          onClick={(_) => setIsOpen(!isOpen)}
          isOpen={isOpen}
        >
          {!isOpen && (
            <>
              <span>{more_label}</span>
              <Icon icon="chevron-down" />
            </>
          )}
          {isOpen && (
            <>
              <span>{less_label}</span>
              <Icon icon="chevron-up" />
            </>
          )}
        </StyledBulletPointsListMore>
      )}
    </StyledBulletPointsWrapper>
  );
};
