import { sbLinkType } from "../../types";

export const getUrl = (link: sbLinkType) => {
  if (link.url || link.cached_url) {
    return link.url ? link.url : `/${link.cached_url}`;
  } else {
    return undefined;
  }
};

export const getKey = (prefix: string, identifier: number | string) => {
  return `${prefix}-${identifier}`;
};

export function SbImageBackgroundService(imageSrc: any) {
  if (
    imageSrc &&
    !isSvg(imageSrc) &&
    imageSrc.startsWith("//a.storyblok.com")
  ) {
    return imageSrc.replace(
      "a.storyblok.com",
      "img2.storyblok.com"
    )
  }

  return imageSrc
}

export function isSvg(imageSrc: any) {
  if (imageSrc) return imageSrc.endsWith(".svg")
  return false
}