import styled from "styled-components";

interface StyledDescWImageCardContainerProps {
  lessThanThree?: number;
}

export const StyledStatsNumber = styled.div`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin-bottom: 50px;
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.five};
    margin-bottom: auto;
  }
`;
export const StyledStatsNumberWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
}
`;
export const StyledStatsNumberTitle = styled.p`
  font-size: 30px;
  width: 82%;
  font-weight: 400px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 130px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: auto;
    font-size: 17px;
    padding-bottom: 65px;
  }
`;
export const StyledStatsNumberContainer = styled.div<StyledDescWImageCardContainerProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.lessThanThree == 2
      ? "1fr 1fr"
      : props.lessThanThree == 3
      ? "1fr 1fr 1fr"
      : "1fr 1fr 1fr 1fr"};
  row-gap: ${(props) => props.theme.spacing.five};
  
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    row-gap: ${(props) => props.theme.spacing.seven};
  }
`;