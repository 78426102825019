import React from "react";

import { sbLinkType } from "../../types";
import { Icon } from "../Icons";

import { StyledLinkItem } from "./partials";

export interface LinkItemProps {
  _uid?: string;
  name: string;
  link: string | sbLinkType;
  spaceApart?: boolean;
  parentComponent?: string;
}

export const LinkItem: React.FC<LinkItemProps> = ({
  name,
  link,
  spaceApart = false,
  parentComponent = "",
}) => {
  const url =
    typeof link === "string"
      ? link
      : link?.url
      ? link?.url
      : `/${link.cached_url}`;

  function handleClick() {
    window.dataLayer.push({
      event: "link_click",
      eventMeta: {
        p_one: parentComponent,
        p_two: name,
        p_three: url,
      },
    });
  }

  return (
    <StyledLinkItem
      onClick={(_) => handleClick()}
      spaceApart={spaceApart}
      href={url}
    >
      {name}
      <Icon icon="arrow-right" />
    </StyledLinkItem>
  );
};
