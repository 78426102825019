import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FacultyCarouselCmp, FacultyCarouselProps } from "./FacultyCarousel";

interface FacultyCarouselWBlokProps {
  blok: FacultyCarouselProps & blokType;
}

export const FacultyCarousel: React.FC<FacultyCarouselWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <FacultyCarouselCmp
        title={blok.title}
        sub_header={blok.sub_header}
        cards={blok.cards}
        divider={blok.divider}
        show_qualifier_modal={blok.show_qualifier_modal}
      ></FacultyCarouselCmp>
    </SbEditable>
  );
};
