import React from "react";
import { FormFields } from "./FormCD";
import { Select } from "../Inputs/Select";
import { RadioButton } from "../Inputs/RadioButton";
import { CheckBox } from "../Inputs/CheckBox";
import { TextArea } from "../Inputs/TextArea";
import { TextDate } from "../Inputs/TextDate";
import { Url } from "../Inputs/Url";
import { Other } from "../Inputs/Other";
import { sbLinkType } from "../../types";
import {
  StyledHelpTextFields,
  StyledOptionsFields,
  StyledOtherFields,
} from "./Partials";
import { TelephoneDropdown } from "../Inputs/TelephoneDropdown";
import { render } from "storyblok-rich-text-react-renderer";

interface FormFieldsCustomProps {
  field: FormFields;
  index: number;
  formFields: FormFields[];
  changeOptions: Function;
}
function buildField(
  field: FormFields,
  formFields: FormFields[],
  changeOptions: Function,
  index: number,
  isValid: any,
  link: string | sbLinkType
) {
  let { label, name, type, options, _uid, datasource, defaultValue, required, maxLength } = field;

  switch (type) {
    case "select":
      if (datasource) {
        const optionFetch = formFields.find(
          (option: any) => option._uid === _uid
        );

        optionFetch && (options = optionFetch.options);
      }

      const initExists = options.some((option) => option.value === "");
      !initExists &&
        options.unshift({
          value: "",
          label: `${label}...`,
          _uid: `option-none-${name}-${index}`,
          component: `option-none-${name}-${index}`,
          defaultValue: "",
          id: 0,
        });
      return (
        <Select
          _uid={_uid}
          defaultValue={defaultValue}
          name={name}
          options={options}
          required={required}
          changeOptions={changeOptions}
          type={type}
          isValid={isValid}
        ></Select>
      );
    case "radio":
      return (
        <RadioButton
          _uid={_uid}
          defaultValue={defaultValue}
          name={name}
          options={options}
          required={required}
          changeOptions={changeOptions}
          type={type}
          isValid={isValid}
        ></RadioButton>
      );
    case "tc":
      return (
        <CheckBox
          _uid={_uid}
          defaultValue={defaultValue}
          name={name}
          options={options}
          required={required}
          changeOptions={changeOptions}
          type={type}
          isValid={isValid}
          link={link}
        ></CheckBox>
      );
    case "checkbox":
      return (
        <CheckBox
          _uid={_uid}
          defaultValue={defaultValue}
          name={name}
          options={options}
          required={required}
          changeOptions={changeOptions}
          type={type}
          isValid={isValid}
          link={link}
        ></CheckBox>
      );
    case "textarea":
      return (
        <TextArea
          _uid={_uid}
          defaultValue={defaultValue}
          name={name}
          required={required}
          changeOptions={changeOptions}
          type={type}
          isValid={isValid}
        ></TextArea>
      );
    case "tel":
      return (
        <TelephoneDropdown
          field={field}
          changeOptions={changeOptions}
        ></TelephoneDropdown>
      );
    case "url":
      return (
        <Url
          defaultValue={defaultValue}
          name={name}
          required={required}
          type={type}
          _uid={_uid}
          isValid={isValid}
          changeOptions={changeOptions}
          maxLength={maxLength}
        ></Url>
      );
    default:
      return (
        <TextDate
          defaultValue={defaultValue}
          name={name}
          required={required}
          type={type}
          _uid={_uid}
          isValid={isValid}
          changeOptions={changeOptions}
          maxLength={maxLength}
        ></TextDate>
      );
  }
}
export const FormFieldsCustom: React.FC<FormFieldsCustomProps> = ({
  field,
  index,
  formFields,
  changeOptions,
}) => {
  let {
    label,
    name,
    type,
    _uid,
    defaultValue,
    required,
    helptext,
    datasource,
    subtitle,
    isValid = null,
    link,
    isFromDifferentUniversity
  } = field;
  const inputField = buildField(
    field,
    formFields,
    changeOptions,
    index,
    isValid,
    link
  );
  let shouldDisplay = true;

  if (isFromDifferentUniversity) {
    const dependentField = formFields.find(
      (f) => f.name === isFromDifferentUniversity
    );

    shouldDisplay = dependentField?.defaultValue === "true";
  }

      return (
        <>
          {shouldDisplay && (
            <>
              {type !== "hidden" && (
                <>
                  {subtitle && render(subtitle)}
                  <label className="titleField" htmlFor={name}>
                    {`${label}${required ? " *" : ""}`}
                  </label>
                </>
              )}
      
              <StyledHelpTextFields>{helptext}</StyledHelpTextFields>
              <StyledOptionsFields>
                {inputField}
                {`${defaultValue}`?.includes("Other") && datasource !== "" && (
                  <StyledOtherFields>
                    <Other
                      defaultValue={defaultValue}
                      name={name}
                      required={required}
                      type={type}
                      _uid={_uid}
                    />
                  </StyledOtherFields>
                )}
              </StyledOptionsFields>
            </>
          )}
        </>
      );      
};