import styled from "styled-components";

export const StyledTimelineStep = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledStepCopy = styled.div`
  border-left: 2px solid #dadada;
  padding: 16px 24px 16px 8px;
  margin-left: 8px;
  margin-top: -7px;
`;

export const StyledTimelineContainer = styled.div``;

export const StyledTopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const StyledTopText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledStepNumber = styled.h3`
  color: ${(props) => props.theme.colors.warmths.pink};
  font-size: 14px;
`;

export const StyledLabel = styled.h3``;

export const StyledDate = styled.p``;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;

  & svg {
    max-width: 36px;
    min-width: 36px;
    max-height: 36px;
    min-height: 36px;
  }
`;

export const StyledRoad = styled.img`
  width: 100%;
  height: auto;
`;
