import { optionSelect } from "../FormWrapper/FormCD";
import React from "react";
import { StyledCheckbox, StyledCheckboxWrapper, StyledRequired, StyledInvalidData } from "./Partials";
import { sbLinkType } from "../../types";

interface CheckBoxProps {
  name: string;
  _uid: string;
  type: string;
  changeOptions: Function;
  defaultValue: any;
  required: boolean;
  options: optionSelect[];
  isValid: boolean;
  link?: string | sbLinkType;
}

export const CheckBox: React.FC<CheckBoxProps> =
  ({ name, _uid, type, changeOptions, defaultValue, required, options, isValid, link }) => {
    const url =
      typeof link === "string"
        ? link
        : link?.url
          ? link?.url
          : `/${link?.cached_url}`;
    return (
      <StyledCheckboxWrapper>
        {isValid === false &&
          (defaultValue.length > 0 ? (
            <StyledInvalidData>Invalid data entry</StyledInvalidData>
          ) : (
            <StyledRequired>This is required</StyledRequired>
          ))}
        {options.map(({ label, value, _uid: _uidC }, idx) => (
          <StyledCheckbox key={`StyledContainerCheckbox-${idx}`}>
            <input
              type='checkbox'
              id={`${_uidC}`}
              name={`${name}`}
              value={value}
              defaultChecked={defaultValue !== ""}
              onClick={($event) => {
                changeOptions($event, name, _uid, type);
              }}
              required={defaultValue?.length === 0}
            />
            <label htmlFor={`${_uidC}`}>
              {
                type === 'tc' ? <span><a href={url} target='_blank'> {label}</a> </span> :
                  <span>{label}</span>
              }

            </label>
          </StyledCheckbox>
        ))}
      </StyledCheckboxWrapper>
    );
  }; 