import * as React from "react";
import { useContext, createContext } from "react";
import { Icon } from "../Icons";
import {
  IPaginationButtonProps,
  StyledPaginationButton,
  StyledPaginationContainer,
  StyledPaginationContent,
  StyledPaginationPage,
} from "./partials";

const SIZE_SMALL = "sm";
const SIZE_LARGE = "lg";
const PaginationContext = createContext({ size: SIZE_SMALL });

export function classNames(...items: string[]): string {
  return items.filter((a) => a).join(" ");
}

export interface ThemeContextType {
  variables: any;
  style: any;
  cssVariables: any;
}

interface IPaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  small?: boolean;
  onPageChange: (count: number, event: React.MouseEvent) => void;
}

interface IPagination extends React.FunctionComponent<IPaginationProps> {
  Button: PaginationButton;
  Content: PaginationContent;
  Page: React.FC<PaginationPage>;
}

interface PaginationPage extends React.HTMLAttributes<HTMLAnchorElement> {
  active: boolean;
  more: boolean;
}

interface PaginationButton
  extends React.FunctionComponent<
    IPaginationButtonProps & React.HTMLAttributes<HTMLButtonElement>
  > {}

interface PaginationContent
  extends React.FC<React.HTMLAttributes<HTMLDivElement>> {}

export const Pagination: IPagination = (props) => {
  const { className, small, children, onPageChange, ...rest } = props;
  const size = small ? SIZE_SMALL : SIZE_LARGE;

  const handleButtonClick = (count: number) => (e: React.MouseEvent) => {
    onPageChange(count, e);
  };

  return (
    <PaginationContext.Provider value={{ size }}>
      <StyledPaginationContainer {...rest}>
        {size === SIZE_SMALL ? (
          <Pagination.Button xsmall onClick={handleButtonClick(-10)}>
            <Icon icon="angle-double-left" />
          </Pagination.Button>
        ) : null}
        <Pagination.Button onClick={handleButtonClick(-1)}>
          <Icon icon="chevron-left" />
        </Pagination.Button>
        <Pagination.Content>{children}</Pagination.Content>
        <Pagination.Button onClick={handleButtonClick(1)}>
          <Icon icon="chevron-right" />
        </Pagination.Button>
        {size === SIZE_SMALL ? (
          <Pagination.Button xsmall onClick={handleButtonClick(10)}>
            <Icon icon="angle-double-right" />
          </Pagination.Button>
        ) : null}
      </StyledPaginationContainer>
    </PaginationContext.Provider>
  );
};

const PaginationButton: PaginationButton = (props) => {
  const { children, xsmall, className, ...rest } = props;
  const { size } = useContext(PaginationContext);

  return (
    <StyledPaginationButton
      xsmall={xsmall}
      small={size === SIZE_SMALL}
      {...rest}
    >
      {children}
    </StyledPaginationButton>
  );
};

const PaginationContent: PaginationContent = (props) => {
  const { className, children } = props;
  const { size } = useContext(PaginationContext);

  const classes = classNames(
    (size === SIZE_SMALL && "ef-pagination--sm__content") || "",
    (size === SIZE_LARGE && "ef-pagination--lg__content") || "",
    className || ""
  );

  return (
    <StyledPaginationContent small={size === SIZE_SMALL} className={classes}>
      {children}
    </StyledPaginationContent>
  );
};

const PaginationPage: React.FC<PaginationPage> = (props) => {
  const { children, className, active, more, ...rest } = props;
  const { size } = useContext(PaginationContext);

  const classes = classNames(
    (size === SIZE_LARGE && "ef-pagination--lg__page") || "",
    (size === SIZE_SMALL && "ef-pagination--sm__page") || "",
    (active && "-is-active") || "",
    (more && "-more") || "",
    className || ""
  );

  return (
    <StyledPaginationPage active={active} {...rest}>
      {children}
    </StyledPaginationPage>
  );
};

Pagination.Page = PaginationPage;
Pagination.Button = PaginationButton;
Pagination.Content = PaginationContent;
