import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FullBleedImageCmp, FullBleedImageProps } from "./FullBleedImage";

interface FullBleedImageWBlokProps {
  blok: FullBleedImageProps & blokType;
}

export const FullBleedImage: React.FC<FullBleedImageWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <FullBleedImageCmp
        image={blok.image}
        component={blok.component}
        video={blok.video}
        video_preview={blok.video_preview}
        asset_link={blok?.asset_link}
        is_square={blok?.is_square}
        title={blok?.title}
        body={blok?.body}
        graphic_overlay={blok.graphic_overlay}
        graphic_overlay_mobile={blok.graphic_overlay_mobile}
        graphic_overlay_placement={blok.graphic_overlay_placement}
      ></FullBleedImageCmp>
    </SbEditable>
  );
};
