import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  maxWidth: "960px",
  articleMaxWidth: "632px",

  breakpoints: {
    s: "600px",
    m: "768px",
    l: "1248px",
  },

  fontSize: {
    s: "12px",
    m: "14px",
    l: "16px",
  },

  spacing: {
    one: "8px",
    two: "16px",
    three: "24px",
    four: "32px",
    five: "40px",
    six: "48px",
    seven: "64px",
    eight: "128px",
    nine: "256px",
  },

  colors: {
    warmths: {
      magenta: "#a30a7b",
      pink: "#e6007f",
      sun: "#ff9900",
    },

    neutrals: {
      black: "#191919",
      coolGrey: "#949494",
      sisal: "#D6CCBF",
      sisal20: "#f7f5f2",
      albescent: "#F5E6CF",
      gallery: "#F0F0F0",
      white: "#ffffff",
    },

    tones: {
      tacao: "#E7AB7F",
      neptune: "#77C3B2",
    },
  },

  font: {
    light: 300,
    book: 400,
    bold: 700,
    black: 900,
  },
};

export { theme };
