import styled from "styled-components";
import { brandColors } from "../../../types";

interface StyledHighlightedListProps {
  background_color?: brandColors;
}

export const StyledHighlightedListWrapper = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledHighlightedList = styled.div<StyledHighlightedListProps>`
  position: relative;
  background-color: ${(props) =>
    props.background_color
      ? props.theme.colors.warmths[props.background_color]
      : props.theme.colors.warmths["magenta"]};
  border-radius: 40px;
`;

export const StyledHighlightedListContent = styled.div<StyledHighlightedListProps>`
  z-index: 5;
  padding: ${(props) => props.theme.spacing.eight}
    ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledAssetWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-width: ${(props) => props.theme.maxWidth};
  width: 100%;
  top: 32px;
  z-index: 0;
  margin: auto;

  & img {
    display: block;
    width: 100%;
    max-width: 960px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: calc(100% - 60px);
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    top: 38px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledHighlightedListContentText = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 75%;
  grid-auto-flow: row;
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledHighlightedListContentQual = styled.h2`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
  color: ${({ theme }) => theme.colors.neutrals.white};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 14px;
  }
`;

export const StyledHighlightedListContentPoint = styled.div`
  > p {
    font-size: 32px;
    font-weight: ${(props) => props.theme.font.bold};
    margin: 0;
    line-height: 40px;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.neutrals.white};
  }

  > hr {
    margin: ${({ theme }) => theme.spacing.three} 0 0 0;
    background: ${({ theme }) => theme.colors.neutrals.white};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    > p {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;
