import React from "react";
import { blokType, sbAssetType, sbLinkType } from "../../types";

import { MetaDataCmp } from "./MetaData";

import { sbImageService } from "hult-sb-ui";
import { getUrl } from "../utils/helpers";

export interface MetaDataBlokProps {
  meta_title: string;
  meta_description: string;
  og_title: string;
  og_description: string;
  og_image: sbAssetType;
  og_type: string;
  og_site_name: string;
  twitter_card: string;
  twitter_image: sbAssetType;
  twitter_site: string;
  structured_data: string;
  no_index: boolean;
  canonical_link: sbLinkType;
}

interface MetaDataWrapperProps {
  blok: MetaDataBlokProps & blokType;
  path: string;
}

const MetaData: React.FC<MetaDataWrapperProps> = ({ blok, path }) => (
  <>
    <MetaDataCmp
      meta_title={blok.meta_title}
      meta_description={blok.meta_description}
      og_title={blok.og_title || blok.meta_title}
      og_description={blok.og_description || blok.meta_description}
      og_image={
        blok.og_image &&
        blok.og_image.filename &&
        sbImageService(blok.og_image.filename, undefined, "1200x600")
      }
      og_image_alt={blok.og_description || blok.meta_description}
      og_type={blok.og_type}
      og_site_name={blok.og_site_name}
      twitter_card={blok.twitter_card}
      twitter_image={
        blok.twitter_image &&
        blok.twitter_image.filename &&
        sbImageService(blok.twitter_image.filename, undefined, "1200x600")
      }
      twitter_site={blok.twitter_site}
      twitter_creator={blok.twitter_site}
      og_url={process.env.GATSBY_HOST + path}
      canonical_link={blok.canonical_link && getUrl(blok.canonical_link)}
      structured_data={blok.structured_data}
      no_index={blok.no_index}
    />
  </>
);

export default MetaData;
