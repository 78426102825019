import styled, { css } from "styled-components";

export interface IPaginationPageProps {
  active?: boolean;
  small?: boolean;
}

export const StyledPaginationPage = styled.a<IPaginationPageProps>`
  border-radius: 4px;
  display: inline-block;
  line-height: 40px;
  margin: 0 15px;
  width: 40px;

  ${({ active }) =>
    active &&
    css`
      background: #e3ebed;
      background-color: rgba(214, 204, 191, 0.5);
    `}
`;
