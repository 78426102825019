import React from "react";

import { sbAssetType } from "../../types";

import {
  StyledFacultyCarouselCard,
  StyledFacultyCarouselCardContent,
  StyledFacultyCarouselCardImg,
  StyledFacultyCarouselCardText,
  StyledFacultyCarouselCardTextBottom,
  StyledFacultyCarouselCardTextHeadline,
  StyledLinkItem
} from "./partials";
import { sbImageService } from "hult-sb-ui";
import { Icon } from "../Icons";

export interface FacultyCarouselCardProps {
  title: string;
  qualifier: string;
  image: sbAssetType;
  body_copy?: string;
  handleClick?: any;
  clickable?: boolean;
  show_link?: boolean;
  link?: any;
}

export const FacultyCarouselCard: React.FC<FacultyCarouselCardProps> = ({
  title,
  qualifier,
  image,
  body_copy,
  handleClick,
  clickable,
  link,
  show_link

}) => {

  return (
    <>
      {
        link && link.id ? (
          <StyledFacultyCarouselCard>
            <StyledLinkItem href={typeof link === "string"
              ? link
              : link?.url
                ? link?.url
                : `/${link.cached_url}`}>

              <StyledFacultyCarouselCardContent>
                <StyledFacultyCarouselCardImg
                  src={sbImageService(image.filename, undefined, "0x330", image.focus)}
                  alt={image.alt}
                  title={image?.title}
                />
                <StyledFacultyCarouselCardText>
                  <StyledFacultyCarouselCardTextHeadline>
                    {title}
                  </StyledFacultyCarouselCardTextHeadline>
                  <StyledFacultyCarouselCardTextBottom>
                    <p>{qualifier}</p>
                    {body_copy && (
                      <div>
                        <Icon icon="plus-circle" />
                      </div>
                    )}
                  </StyledFacultyCarouselCardTextBottom>
                </StyledFacultyCarouselCardText>
              </StyledFacultyCarouselCardContent>
            </StyledLinkItem>
          </StyledFacultyCarouselCard>
        )
          :
          (<StyledFacultyCarouselCard
            onClick={handleClick}
            clickable={clickable}
          >
            <StyledFacultyCarouselCardContent>
              <StyledFacultyCarouselCardImg
                src={sbImageService(image.filename, undefined, "0x330", image.focus)}
                alt={image.alt}
                title={image?.title}
              />
              <StyledFacultyCarouselCardText>
                <StyledFacultyCarouselCardTextHeadline>
                  {title}
                </StyledFacultyCarouselCardTextHeadline>
                <StyledFacultyCarouselCardTextBottom>
                  <p>{qualifier}</p>
                  {body_copy && (
                    <div>
                      <Icon icon="plus-circle" />
                    </div>
                  )}
                </StyledFacultyCarouselCardTextBottom>
              </StyledFacultyCarouselCardText>
            </StyledFacultyCarouselCardContent>
          </StyledFacultyCarouselCard>

          )

      }

    </>
  );
};
