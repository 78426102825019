import styled, { css } from "styled-components";
import { brandColors, brandPink } from "../../../types";

interface ButtonProps {
  color?: brandColors | brandPink;
  as?: "a" | "button";
  full_width?: boolean;
  variant?: "primary" | "secondary";
  disabled?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  height: 48px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 16px 32px;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  width: ${(props) => (props.full_width ? "100%" : "min-content")};
  color: ${(props) => props.theme.colors.neutrals.white};
  border: none;
  border-radius: 24px;

  background: ${(props) =>
    props.color
      ? props.theme.colors.warmths[props.color]
      : props.theme.colors.warmths.magenta};

  &:hover {
    box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
  }

  &:focus {
    box-shadow: 0 2px 8px
      ${(props) =>
        props.color
          ? props.theme.colors.warmths[props.color]
          : props.theme.colors.warmths.magenta};
  }

  :disabled {
    opacity: 0.6;
    box-shadow: none;
  }

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: white;
      color: black;
      border: 1px solid rgba(25, 25, 25, 0.2);

      &:hover {
        box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
        border: 1px solid rgba(25, 25, 25, 0.13);
      }
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 48px;
    border-radius: 24px;
    padding: 16px 32px;
    line-height: 16px;
  }
`;
