import styled from "styled-components";

export interface IPaginationButtonProps {
  xsmall?: boolean;
  small?: boolean;
}

export const StyledPaginationButton = styled.button<IPaginationButtonProps>`
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  justify-content: center;
  line-height: 16px;
  outline: none;
  position: relative;
  text-overflow: ellipsis;
  transition: all 0.15s ease;
  user-select: none;
  white-space: nowrap;

  align-items: center;

  border-radius: 4px;

  padding: ${(props) => {
    if (props.xsmall) return "15px 8px";
    return props.small ? "15px 16px" : "15px 32px";
  }};

  background: white;
  color: #191919;
  box-shadow: 0 2px 8px 0 rgb(25 25 25 / 20%);
  border: 1px solid transparent;

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }
`;
