import React from "react";

import { LinkItemProps, LinkItem } from "../LinkItem";
import { ClientLogosCard } from "./ClientLogosCard";

import {
  StyledClientLogos,
  StyledClientLogosWrapper,
  StyledClientLogosHeader,
  StyledClientLogosCardsWrapper,
  StyledClientLogosSubheader,
} from "./partials";

export interface ClientLogosProps {
  title: string;
  component?: string;
  sub_header: string;
  client_cards: Array<any>;
  link?: LinkItemProps;
}

export const ClientLogosCmp: React.FC<ClientLogosProps> = ({
  title,
  component,
  sub_header,
  link,
  client_cards,
}) => {
  return (
    <StyledClientLogos id={component}>
      <StyledClientLogosWrapper>
        <StyledClientLogosHeader>{title}</StyledClientLogosHeader>
        <StyledClientLogosSubheader>{sub_header}</StyledClientLogosSubheader>
      </StyledClientLogosWrapper>
      <StyledClientLogosCardsWrapper addWrapper>
        {client_cards.map((item, idx) => (
          <ClientLogosCard key={idx} logo={item.logo} />
        ))}
      </StyledClientLogosCardsWrapper>

      <StyledClientLogosWrapper>
        {Array.isArray(link) && link.length === 1 && (
          <LinkItem
            link={link[0].link}
            name={link[0].name}
            parentComponent={component}
          />
        )}
      </StyledClientLogosWrapper>
    </StyledClientLogos>
  );
};
