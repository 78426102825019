import styled from "styled-components";
import { Link } from "gatsby";

interface Props {
  open: any;
}
export const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 15px 0;
  }
`;
export const Overlay = styled.div<Props>`
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 1;
  top: 0px;
  display: ${({ open }) => (open ? "blok" : "none")};
`;
export const StyledBurger = styled.div<Props>`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 1.8rem;
    height: 0.15rem;
    background-color: #211f1f;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
export const RegisterButton = styled.a`
  cursor: pointer;
  padding: 10px 25px;
  line-height: 16px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.neutrals.white};
  border: none;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.warmths.pink};
  text-decoration: none;
  height: 20px;
  
  &:hover {
    box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
    color: ${(props) => props.theme.colors.neutrals.white};
  }

  &:focus {
    box-shadow: 0 2px 8px ${(props) => props.theme.colors.warmths.magenta};
  }
  span {
    display: none;
  }
  @media (max-width: 768px) {
    width: 65%;
    position: absolute;
    bottom: 20px;
    span {
      display: block;
      position: absolute;
      right: 20px;
      top: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    width: 65%;
    position: absolute;
    bottom: 20px;
    span {
      display: block;
      position: absolute;
      right: 20px;
      top: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export const MobRegisterButton = styled.a<Props>`
  cursor: pointer;
  padding: 10px 15px;
  line-height: 16px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.neutrals.white};
  border: none;
  border-radius: 24px;
  background: ${(props) => props.theme.colors.warmths.pink};
  text-decoration: none;
  position: absolute;
  display: none;

  &:hover {
    box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
    color: ${(props) => props.theme.colors.neutrals.white};
  }

  &:focus {
    box-shadow: 0 2px 8px ${(props) => props.theme.colors.warmths.magenta};
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.open ? "none" : "block")};
    right: 70px;
  }
  @media (max-width: 767px) {
    display: ${(props) => (props.open ? "none" : "block")};
    right: 60px;
    font-size: 12px;
    padding: 6px 10px;
  }
`;
export const NavBrand = styled(Link)`
  position: absolute;
  top: 20px;
  z-index: 1001;
  width: 40px;
  height: 40px;
  display: none;
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;
export const RightNav = styled.ul<Props>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index: 1000;
  margin-left: auto;
  margin-top: 0px;
  position: relative;

  @media (max-width: 768px) {
    visibility: ${({ open }) => (open ? "visible" : "hidden")};
    align-items: flex-start;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: #fff;
    position: absolute;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 414px;
    transition: all 0.4s ease-out;
    opacity: ${({ open }) => (open ? "1" : "0")};
    overflow: scroll;
  }
  @media (max-width: 767px) {
    width: calc(100% - 115px);
  }
`;
export const NavMenuLink = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: black;
  &:hover {
    color: #E6007F;
  }
`;
export const NavItem = styled.li`
  list-style: none;
  margin: 0 auto;
  display: inline-block;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #3ca0e7;
  }

  @media (max-width: 768px) {
    margin: 0px;
    border: 0px;
    width: 85%;
    text-align: left;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
  }
`;

