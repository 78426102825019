import React from "react";
import {
  StyledStatsNumber,
  StyledStatsNumberWrapper,
  StyledStatsNumberTitle,
  StyledStatsNumberContainer,
} from "./Partials";
import {
  StatsNumberIndicator,
  StatsNumberIndicatorProps,
} from "./StatsNumberIndicators";

export interface StatsNumberProps {
  title: string;
  indicators: Array<StatsNumberIndicatorProps>;
}

export const StatsNumberCmp: React.FC<StatsNumberProps> = ({
  title,
  indicators,
}) => {
  return (
    <StyledStatsNumber id={title.toLowerCase().replace(/ /g, "-")}>
      <StyledStatsNumberWrapper>
        <StyledStatsNumberTitle>{title}</StyledStatsNumberTitle>
        <StyledStatsNumberContainer lessThanThree={indicators.length}>
          {indicators.map((item, idx) => (
            <StatsNumberIndicator
              key={idx}
              image={item.image}
              number={item.number}
              quantity={item.quantity}
              description={item.description}
            ></StatsNumberIndicator>
          ))}
        </StyledStatsNumberContainer>
      </StyledStatsNumberWrapper>
    </StyledStatsNumber>
  );
};