import styled from "styled-components";

export const StyledTextarea = styled.textarea`
    border: 2px solid #a6a6a6;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 18px;
    width:50%;
    height: 100px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        width:auto !important;
    }
`;