import React from "react";
import { render } from "storyblok-rich-text-react-renderer";
import road from "../../assets/images/timeline-road.png";
import {
  StyledTimelineStep,
  StyledTimelineContainer,
  StyledTopWrapper,
  StyledTopText,
  StyledStepNumber,
  StyledLabel,
  StyledDate,
  StyledStepCopy,
  StyledIcon,
  StyledRoad,
} from "./partials";
import { Icon } from "../Icons";

export interface TimelineStepProps {
  number: number;
  label?: string;
  date?: string;
  copy?: any;
}

export const TimelineStep: React.FC<TimelineStepProps> = ({
  number,
  label,
  date,
  copy,
}) => {
  return (
    <StyledTimelineStep>
      <StyledTimelineContainer>
        <StyledTopWrapper>
          <StyledTopText>
            <StyledStepNumber>Step {number}</StyledStepNumber>
            <StyledLabel>{label}</StyledLabel>
            <StyledDate>{date}</StyledDate>
          </StyledTopText>
          <StyledIcon>
            <Icon lib="fas" icon="map-marker-alt" />
          </StyledIcon>
        </StyledTopWrapper>
        <StyledRoad src={road} />
        <StyledStepCopy>{render(copy)}</StyledStepCopy>
      </StyledTimelineContainer>
    </StyledTimelineStep>
  );
};
