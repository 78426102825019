import React from "react";

import { StyledBodyContainer, StyledSpacer } from "./partials";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import Seo from "../../utils/seo";

interface PageProps extends BaseComponentProps {
  blok: blokType & {
    header?: Array<blokType>;
    body?: Array<blokType>;
    contact_us?: Array<blokType>;
    meta_data?: Array<blokType>;
  };
}

const Page: React.FC<PageProps> = ({ blok, story }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
      />
      <StyledBodyContainer>
        {blok?.meta_data?.map((seoBlok) => {
          return (
            <DynamicComponent
              key={seoBlok._uid}
              blok={seoBlok}
              path={story?.path ? story?.path : story?.full_slug}
            />
          );
        })}
        {blok?.header?.map((headerBlok) => {
          return <DynamicComponent key={headerBlok._uid} blok={headerBlok} />;
        })}
        {blok?.body?.map((bodyBlok) => {
          return <DynamicComponent key={bodyBlok._uid} blok={bodyBlok} />;
        })}
        {blok?.contact_us?.map((contactBody) => {
          return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
        })}
      </StyledBodyContainer>
    </>
  );
};

export default Page;
