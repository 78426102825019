import styled from "styled-components";
import {
  StyledOverview,
  StyledOverviewCard,
  StyledOverviewCardImgContainer,
  StyledOverviewCardAside,
  StyledOverviewCardTitle,
} from ".";

export const StyledEventOverview = styled(StyledOverview)``;
export const StyledEventOverviewCard = styled(StyledOverviewCard)``;
export const StyledEventCardImgContainer = styled(
  StyledOverviewCardImgContainer
)``;

export const StyledEventOverviewCardAside = styled(StyledOverviewCardAside)``;

export const StyledEventOverviewDetails = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: ${({ theme }) => theme.spacing.three};
  font-size: 14px;

  & span {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing.four};

    & svg {
      margin-right: ${({ theme }) => theme.spacing.two};
    }
  }
`;

export const StyledEventOverviewCardTitle = styled(StyledOverviewCardTitle)``;

export const StyledEventOverviewDetailsLocation = styled.span`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none !important;
  }
`;
