import React from "react";
import {
  DescriptionWithImageCard,
  DescriptionWithImageCardProps,
} from "./DescriptionWithImageCard";

import {
  StyledDescWImage,
  StyledDescWImageWrapper,
  StyledDescWImageCardContainer,
  StyledDescWImageHeader,
} from "./partials";

export interface DescriptionWithImageProps {
  title: string;
  body: string;
  cards: Array<DescriptionWithImageCardProps>;
  component: string;
}

export const DescriptionWithImageCmp: React.FC<DescriptionWithImageProps> = ({
  title,
  body,
  cards,
  component,
}) => {
  return (
    <StyledDescWImage id={title.toLowerCase().replace(/ /g, "-")}>
      <StyledDescWImageWrapper>
        <StyledDescWImageHeader>
          {title && <h2>{title}</h2>}
          {body && <p>{body}</p>}
        </StyledDescWImageHeader>
        <StyledDescWImageCardContainer lessThanThree={cards.length <= 2}>
          {cards.map((item, idx) => (
            <DescriptionWithImageCard
              key={idx}
              title={item.title}
              description={item.description}
              image={item.image}
              link={item.link}
              social_links={item.social_links}
              enable_cta={item.enable_cta}
              label={item.label}
            />
          ))}
        </StyledDescWImageCardContainer>
      </StyledDescWImageWrapper>
    </StyledDescWImage>
  );
};
