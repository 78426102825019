import React, { createContext, useEffect, useState } from "react";
import {
  blokType,
  sbAssetType,
  sbLinkType,
  IStoryType,
  sbRichText,
} from "../types";

import { IFooter } from "../components/Footer";

interface ISettingsContent {
  logo?: sbAssetType;
  logo_url?: sbLinkType;
  footer?: Array<IFooter>;
  cookie_title?: string;
  cookie_notice?: sbRichText;
  page_links?: any;
  sections?: any;
  enable_cta?: boolean;
  cta_link?: any;
}

interface ISettings extends IStoryType {
  content?: blokType & ISettingsContent;
}

interface ISettingsContextProps {
  settings?: ISettings;
  setSettings?: React.Dispatch<React.SetStateAction<ISettings>>;
}

const defaultValue: ISettingsContextProps = {
  settings: {},
  setSettings: () => null,
};

export const SettingsContextHooks = createContext(defaultValue);

const SettingsContextHooksProvider: React.FC<ISettingsContextProps> = ({
  children,
  settings: settingsFromProps = {},
}) => {
  const [settings, setSettings] = useState(settingsFromProps);

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <SettingsContextHooks.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContextHooks.Provider>
  );
};

export default SettingsContextHooksProvider;
