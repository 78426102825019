import styled from "styled-components";

export const StyledClientLogosCard = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.six};
  border: solid 1px rgba(148, 148, 148, 0.3);
  // margin-right: ${(props) => props.theme.spacing.three};
  // margin-bottom: ${(props) => props.theme.spacing.three};
  // width: 222px;
  aspect-ratio: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    // width: 163px;
    // height: 163px;
    padding: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledClientLogosCardImgContainer = styled.div`
  // height: 126px;
  height: 100%;
  min-height: 122px;
  min-width: 122px;

  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    // height: 93px;
    min-height: 68px;
    min-width: 68px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    // height: 93px;
    min-height: 60px;
    min-width: 60px;
  }
`;
