import styled, { css } from "styled-components";

interface ControlsItemProps {
  disabled?: boolean;
  right?: boolean;
}

export const StyledFacultyCarouselModal = styled.div``;

export const StyledFacultyCarouselModalWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFacultyCarouselModalSwiperContainer = styled.div`
  & .swiper-container {
    & svg {
      overflow: visible;
    }
  }
`;

export const StyledFacultyCarouselModalContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 500px);
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledFacultyCarouselModalTextContent = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-row-start: 1;
  }
`;

export const StyledFacultyCarouselModalContentImg = styled.img`
  height: 330px;
  width: 100%;
  max-width: 588px;
  object-fit: cover;

  border-radius: 4px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 242px;
    max-width: 100%;
  }
`;

export const StyledFacultyCarouselModalTextTitle = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledFacultyCarouselModalTextSubHeader = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.four};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledFacultyCarouselModalTextBody = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  white-space: pre-line;
  margin-bottom: 0;
`;

export const StyledFacultyCarouselModalPaginationContainer = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.seven};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledFacultyCarouselModalControlsItem = styled.div<ControlsItemProps>`
  position: absolute;
  cursor: pointer;
  ${(props) =>
    props.right &&
    css`
      right: 0;
      margin-right: ${(props) => props.theme.spacing.two};
    `}
  ${(props) =>
    !props.right &&
    css`
      margin-left: ${(props) => props.theme.spacing.two};
    `}
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.two};
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  align-items: center;
  overflow: visible;

  & span {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    overflow: visible;
    color: ${(props) => props.theme.colors.neutrals.black};
    transition: transform 500ms;
  }

  :hover {
    & svg {
      transform: ${(props) =>
        props.right ? "translateX(16px)" : "translateX(-16px)"};
    }
  }
`;
