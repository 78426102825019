import React, { useContext, useState } from "react";
import {
  StyledGlobalNavigation,
  StyledGlobalNavigationInner,
  StyledHeaderLogo,
  StyledBurger,
  RightNav,
  NavItem,
  NavMenuLink,
  RegisterButton,
  MobRegisterButton,
  Overlay,
} from "./partials";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { SbImage } from "hult-sb-ui";

export const GlobalNavigation: React.FC = ({}) => {
  const [open, setOpen] = useState(false);
  const { settings } = useContext(SettingsContextHooks);

  return (
    <>
      <Overlay open={open} />
      <StyledGlobalNavigation>
        <StyledGlobalNavigationInner>
          {settings?.content && settings?.content.logo && (
            <StyledHeaderLogo href={settings?.content?.logo_url?.url}>
              <SbImage image={settings?.content?.logo} />
            </StyledHeaderLogo>
          )}
          {settings?.content?.page_links && (
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
              <div />
              <div />
              <div />
            </StyledBurger>
          )}
          {settings?.content?.enable_cta && (
            <MobRegisterButton
              open={open}
              href={settings?.content?.cta_link[0].link.url}
              target="_blank"
            >
              {settings?.content?.cta_link[0].name}
            </MobRegisterButton>
          )}
          <RightNav open={open}>
            {settings?.content?.page_links?.map((item: any, index: any) => (
              <NavItem key={index}>
                <NavMenuLink to={`/${item.link.cached_url}`}>
                  {item.name}
                </NavMenuLink>
              </NavItem>
            ))}
            {settings?.content?.enable_cta && (
              <RegisterButton
                href={settings?.content?.cta_link[0].link.url}
                target="_blank"
              >
                {settings?.content?.cta_link[0].name}
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="arrow-right"
                    className="svg-inline--fa fa-arrow-right fa-w-14 "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"
                    ></path>
                  </svg>
                </span>
              </RegisterButton>
            )}
          </RightNav>
        </StyledGlobalNavigationInner>
      </StyledGlobalNavigation>
    </>
  );
};
