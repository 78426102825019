import React from "react";
import {
  StyledCompetitionTimeline,
  StyledTitleWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledSteps,
  StyledStep,
} from "./partials";
import { TimelineStep, TimelineStepProps } from "./TimelineStep";

export interface CompetitionTimelineCmpProps {
  title?: string;
  subtitle?: string;
  steps?: Array<TimelineStepProps>;
}

export const CompetitionTimelineCmp: React.FC<CompetitionTimelineCmpProps> = ({
  title,
  subtitle,
  steps,
}) => {
  let number = 1;

  return (
    <StyledCompetitionTimeline>
      <StyledTitleWrapper>
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
      </StyledTitleWrapper>
      <StyledSteps>
        {steps &&
          steps.map((step) => (
            <StyledStep number={number}>
              <TimelineStep
                number={number++}
                label={step.label}
                date={step.date}
                copy={step.copy}
              />
            </StyledStep>
          ))}
      </StyledSteps>
    </StyledCompetitionTimeline>
  );
};
