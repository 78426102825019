import styled, { css } from "styled-components";
import { brandColors } from "../../../types";

interface StyledBodyCopyProps {
  position?: "left" | "center";
  color?: brandColors;
}

export const StyledBodyCopy = styled.div`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledBodyCopyWrapper = styled.section<StyledBodyCopyProps>`
  margin: 0 auto;
  width: 100%;
  ${({ position, theme }) => {
    if (position === "center")
      return css`
        max-width: 53em;
      `;
    return css`
      max-width: ${theme.maxWidth};
    `;
  }}
`;

export const StyledBodyCopyTitle = styled.h2`
  margin: 0 0 24px;
  width: 58%;
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    font-size: 24px;
  }
`;

export const StyledBodyCopyContent = styled.section<StyledBodyCopyProps>`
  ${({ position }) =>
    position !== "center" &&
    css`
      width: 58%;
    `}
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};

  & p {
    font-weight: ${(props) => props.theme.font.light};
    line-height: 24px;
  }

  & a {
    text-decoration: underline;
  }

  & ul,
  ol {
    /* color: ${(props) =>
      props.color
        ? props.theme.colors.warmths[props.color]
        : props.theme.colors.warmths.magenta}; */
    padding-left: 20px;
    margin: 0 0 ${(props) => props.theme.spacing.three} 0;

    & li {
      padding: 0;
      margin-bottom: ${(props) => props.theme.spacing.one};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
