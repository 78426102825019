import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { HeroHomeCmp, HeroHomeProps } from "./HeroHomePage";

interface HeroHomeWBlokProps {
  blok: HeroHomeProps & blokType;
}

export const HeroHomePage: React.FC<HeroHomeWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HeroHomeCmp
        title={blok.title}
        background_image={blok?.background_image }
      ></HeroHomeCmp>
    </SbEditable>
  );
};