import styled from "styled-components";

export const StyledFormWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
  
`;

export const StyledFormWrapperContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledFormWrapperTitle = styled.h2`
  font-size: 32px;
  margin-bottom: ${({ theme }) => theme.spacing.five};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 30px;
    max-width: 100%;
  }
`;

export const StyledFormMessage = styled.h4`
    margin-top: 5%;
    color: red;
    font-size: 17px;
`;