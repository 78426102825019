import React from "react";
import { blokType, brandColors } from "../../types";
import { getKey } from "../utils/helpers";

import { ContactUsCard } from "./ContactUsCard";

import {
  StyledContactUs,
  StyledContactUsContent,
  StyledContactUsHeadline,
  StyledContactUsDesc,
  StyledContactUsCardContainer,
} from "./partials";

export interface ContactUsProps extends blokType {
  title: string;
  description: string;
  component: string;
  cards: Array<any>;
  brand_colour?: brandColors;
}

export const ContactUs: React.FC<ContactUsProps> = ({
  _uid,
  title,
  component,
  description,
  cards,
  brand_colour,
}) => {
  return (
    <StyledContactUs id={component}>
      <StyledContactUsContent>
        <StyledContactUsHeadline>{title}</StyledContactUsHeadline>
        <StyledContactUsDesc>{description}</StyledContactUsDesc>
        {Array.isArray(cards) && cards.length > 0 && (
          <StyledContactUsCardContainer cards={cards.length}>
            {cards.map((item, idx) => (
              <ContactUsCard
                key={getKey(`${_uid}-contact-us-card-item`, idx)}
                component={component}
                title={item.title}
                body={item.body}
                brand_colour={brand_colour}
                tel={item.tel}
                link={item.link}
              />
            ))}
          </StyledContactUsCardContainer>
        )}
      </StyledContactUsContent>
    </StyledContactUs>
  );
};
