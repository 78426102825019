import React from "react";
import { sbAssetType } from "../../types";
import { LinkItemProps, LinkItem } from "../LinkItem";
import { sbImageService } from "hult-sb-ui";
import { ButtonHEF } from "../Button";
import { brandColors } from "../../types";
import { getKey, getUrl } from "../utils/helpers";
import { Icon } from "../Icons";

import {
  StyledDescWImageCard,
  StyledDescWImageCardImg,
  StyledDescWImageCardTitle,
  StyledDescWImageCardBody,
  StyledSocialBar,
  StyledLinkSocialItem,
  StyledLabel
} from "./partials";

export interface DescriptionWithImageCardProps {
  title: string;
  description: string;
  image: sbAssetType;
  link: Array<LinkItemProps>;
  enable_cta?: boolean;
  background_colour?: brandColors;
  social_links?: Array<any>;
  label?: string;
}

export const DescriptionWithImageCard: React.FC<DescriptionWithImageCardProps> =
  ({ title, description, image, link, social_links, label, enable_cta, background_colour = "magenta", }) => {
    return (
      <StyledDescWImageCard>
        {label && <StyledLabel>{label}</StyledLabel>}

        <StyledDescWImageCardImg
          src={sbImageService(image.filename, undefined, "410x0", image.focus)}
          alt={image.alt}
          title={image?.title}
          loading="lazy"
        />
        <StyledDescWImageCardTitle>{title}</StyledDescWImageCardTitle>
        
          <StyledDescWImageCardBody linkBottom={link && link.length == 1}>
            {description}
          </StyledDescWImageCardBody>
        
        { social_links && social_links?.length > 0 ?
          <StyledSocialBar>
            {social_links.map((sIcon, idx: number) => (
              <StyledLinkSocialItem
                key={getKey("footer-social-bar-item-", idx)}
                href={getUrl(sIcon.link)}
                target='_blank'
              >
                <Icon lib="fab" icon={sIcon.icon} />
              </StyledLinkSocialItem>
            ))}
          </StyledSocialBar>
          :
          Array.isArray(link) && link.length === 1 && enable_cta == true ?
            <ButtonHEF
              as={"a"}
              variant="primary"
              parentComponent="description-with-image-card"
              href={link[0].link}
              label={link[0].name}
              color={background_colour}
            ></ButtonHEF>
            :
            Array.isArray(link) && link.length === 1 && (
              <LinkItem
                link={link[0].link}
                name={link[0].name}
                parentComponent="description-with-image-card"
              />
            )

        }

      </StyledDescWImageCard>
    );
  };
