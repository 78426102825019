import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { HeaderCmp, HeaderProps } from "./Header";

interface HeaderWBlokProps {
  blok: HeaderProps & blokType;
}

export const Header: React.FC<HeaderWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HeaderCmp
        title={blok.title}
        component={blok.component}
        description={blok.description}
        image={blok.image}
        video={blok.video}
        video_preview={blok.video_preview}
        asset_link={blok.asset_link}
        link={blok?.link}
        alternative_variant={blok?.alternative_variant}
        background_image={blok?.background_image }
        image_description={blok?.image_description }
      ></HeaderCmp>
    </SbEditable>
  );
};
