import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { FormCD } from "./FormCD";
import { FormCI } from "./FormCI";
import { FormStartup } from "./FormStartup";

interface IForm {
  formUuid: string;
}

interface edges {
  node: { uuid: React.Key | null | undefined; content: string };
}

export const Form: React.FC<IForm> = ({ formUuid }) => (
  <StaticQuery
    query={graphql`
      query {
        allStoryblokEntry(
          filter: { full_slug: { regex: "/^config/forms*/" } }
        ) {
          edges {
            node {
              uuid
              content
            }
          }
        }
      }
    `}
    render={(data) => {
      let form: any;

      if (data && data.allStoryblokEntry) {
        const { allStoryblokEntry } = data;
        const { edges } = allStoryblokEntry;

        if (!edges.length) return;

        const formEdges = edges.find(
          ({ node: { uuid } }: edges) => uuid === formUuid);

        const { node: { content } } = formEdges;

        const blok = JSON.parse(content);
        const { form_type } = blok;
        switch (form_type) {
          case 'web-to-lead':
            form = <FormCD blok={blok} />;
            break;
          case 'updated-lead':
            form = <FormCI blok={blok} />;
            break;
          case 'create-startup':
            form = <FormStartup blok={blok} />;
            break;
        }
      }

      return form;
    }}
  />
);