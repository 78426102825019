import styled from "styled-components";

interface StyledHeaderWrapperProps {
  readonly reverse?: boolean;
  baImage?: string
  position?: string
}

export const StyledHeader = styled.div<StyledHeaderWrapperProps>`
  padding: ${(props) => props.baImage ? '0px' : props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.seven} 0;

  height: ${(props) => props.baImage ? '80vh' : 'auto'};
  position: relative;
  margin-bottom:50px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) =>  props.baImage ? '0px' : props.theme.spacing.seven} ${(props) => props.baImage ? '0px' : props.theme.spacing.five};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.baImage ? '0px' : props.theme.spacing.four} ${(props) => props.baImage ? '0px' : props.theme.spacing.three};
    height: ${(props) => props.baImage ? '70vh' : 'auto'};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    height: ${(props) => props.baImage ? '70vh' : 'auto'};
  }
`;

export const StyledHeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: ${(props) => props.baImage ? props.theme.spacing.eight : '0'} 0 ${(props) => props.baImage ? props.theme.spacing.seven : '0'} 0;


  & h1,
  & h2 {
    font-size: 32px;
    margin-bottom: ${(props) => props.theme.spacing.one};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) =>  props.baImage ? props.theme.spacing.seven : '0'} ${(props) => props.baImage ? props.theme.spacing.five : '0'};
    & p {
      margin-top:0px;
      
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;
    padding: ${(props) => props.baImage ? props.theme.spacing.four : '0'} ${(props) => props.baImage ? props.theme.spacing.three : '0'};

    & h1,
    & h2 {
      font-size: 24px;
      margin-top: ${(props) => props.theme.spacing.three};
      margin-bottom: ${(props) => props.theme.spacing.one};
      max-width:  ${(props) => props.baImage ? '60%' : ''};
    }

    & p {
      margin-top: ${(props) => props.baImage ? '1rem' : '' };
      max-width:  ${(props) => props.baImage ? '60%' : ''};
    }
  }
`;

export const StyledHeaderContentWrapper = styled.div<StyledHeaderWrapperProps>`
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex-basis: 43%;
  white-space: pre-line;
`;

export const StyledHeaderDescription = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: ${({ theme }) => theme.spacing.three}!important;
`;

export const StyledHeaderAssetWrapper = styled.div<StyledHeaderWrapperProps>`
  height: 263px;
  position: relative;
  flex-basis: 50%;
  width: 100%;
  border-radius: 4px;

  & img {
    border-radius: inherit;
    height: 100%;
    max-height: 263px;
    width: 100%;
    object-fit: cover;
  }

  & iframe {
    height: 100%;
    border: none;
    max-height: 263px;
    border-radius: inherit;
  }

  & video {
    height: 100%;
    width: 100%;
    max-height: 263px;
    border: inherit;
    border-radius: 4px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-basis: 100%;
    margin: 0 auto;
    margin-bottom: 0;
  }
`;
export const StyledDescriptionAssetWrapper = styled.div<StyledHeaderWrapperProps>`
  height: 153px;
  position: relative;
  flex-basis: 50%;
  width: 100%;
  border-radius: 4px;

  & img {
    border-radius: inherit;
    height: 100%;
    max-height: 153px;
    width: 25%;
    object-fit: cover;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & img {
      width: 15%;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    & img {
      width: 20%;
    }
  }
`;
export const StyledPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBackground = styled.div`
background-repeat: no-repeat; 
background-size: cover;
background-position: top;
position: relative;
width: 100%;
height: 100%;
position: absolute;
z-index: -1;
`
export const StyledHeroFull = styled.div`
  height: calc(60vh - 89px);
  background-repeat: no-repeat; 
  background-size: cover;
  background-position: top;
  position: relative;
  @include fb;
  @include fb-ai(flex-end);
  @include mq($tablet-portrait){
    max-width: 100%; 
    height: auto;
  }

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    opacity: .8;
  }

  & container {
    position: relative;
  }
  & content{
    width: 100%;
    max-width: 50%;
    padding: 4.8rem 0;
    @include mq($tablet-portrait){
      max-width: 100%;
    }
  }
  & heading {
    margin: 0; 
    color: $co-1;
  }
  & copy {
    margin: 1.6rem 0; 

    & p{
      color: $co-wt-80;
    }
  }
  & btn{
    margin: 4.8rem 0 0 0;
    @include fb;
  }
  `;
