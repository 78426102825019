import React, { useEffect, useState } from "react";
import { StyledInput, StyledRequired, StyledInvalidData } from "./Partials";

interface TextDateProps {
  name: string;
  type: string;
  _uid: string;
  defaultValue: any;
  required: boolean;
  changeOptions: Function;
  isValid: boolean;
  maxLength?: number;
}

export const TextDate: React.FC<TextDateProps> =
  ({ name, type, defaultValue, required, _uid, changeOptions, isValid, maxLength }) => {

    const [formatDate, setFormatDate] = useState(defaultValue);
    useEffect((): void => {
      if (type === "date") {
        if (defaultValue && defaultValue.includes("-")) {
          const [year, month, day] = defaultValue?.split("-");
          defaultValue = `${month}/${day}/${year}`;
          setFormatDate(`${month}/${day}/${year}`);
        }
      }
    }, [])

    let pattern = null;
    if (type === "email") {
      pattern =
        "^[\\p{L}/gu0-9.!#$%&+'^_`{}~-]+@[\\p{L}/gu0-9-]+[.]+(?:.[\\p{L}/gu0-9-]+)*$"
    } else {
      pattern = "^[^()><{}~;]*$"
    }

    return (
      <>
        {isValid === false &&
          (defaultValue.length > 0 ? (
            <StyledInvalidData>Invalid data entry</StyledInvalidData>
          ) : (
            <StyledRequired>This is required</StyledRequired>
          ))}
          {maxLength && defaultValue.length >= maxLength && (
        <StyledInvalidData>The max length is {maxLength} characters</StyledInvalidData>
      )}
        <StyledInput
          defaultValue={defaultValue}
          id={_uid}
          pattern={pattern}
          name={type === "date" ? "" : name}
          type={type}
          onChange={($event: any) => {
            if (type === "date") {
              const [year, month, day] = $event.target.value.split("-");
              setFormatDate(`${month}/${day}/${year}`);
            }
            const { validity: { valid } } = $event.target;
            const isMaxLengthExceeded = maxLength && $event.target.value.length >= maxLength;
            const updatedValid = isMaxLengthExceeded ? false : valid;
            changeOptions($event, name, _uid, type, updatedValid);
          }}
          required={required}
        />
        {type === "date" && (
          <StyledInput
            defaultValue={formatDate}
            id={`date-${name}`}
            name={name}
            type="hidden"
            required={required}
          />
        )}
      </>
    );
  };