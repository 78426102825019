import styled from "styled-components";

export const StyledOpenProgramsOverviewWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.seven} 0;
  background-color: white;
`;

export const StyledOpenProgramsOverview = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.eight} 0;
  background-color: ${({ theme }) => theme.colors.neutrals.sisal20};
  transition: height 0.66s ease-out;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.eight}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.eight}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
    grid-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledOpenProgramsOverviewTopSVG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 32px;
  }

  & svg {
    height: 64px;
    width: 100%;
  }
`;

export const StyledOpenProgramsOverviewBottomSVG = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 64px;
  width: 100%;

  & svg {
    bottom: 0;
    position: absolute;
    height: 64px;
    width: 100%;
  }
`;

interface StyledOpenProgramsOverviewContent {
  bodyExists?: boolean;
}

export const StyledOpenProgramsOverviewContent = styled.div<StyledOpenProgramsOverviewContent>`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  height: auto;
  transition: height 0.66s ease-out;

  & h2 {
    font-size: 32px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${({ theme }) => theme.spacing.one};
    max-width: 65%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 24px;
      max-width: 100%;
    }
  }

  > p {
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.light};
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

export const StyledOpenProgramsOverviewCard = styled.div<{
  qualifier_colour?: string;
}>`
  position: relative;
  display: block;
  height: 100%;
`;

export const StyledOpenProgramsLearningFormats = styled.div`
  border-bottom: 1px solid rgba(25, 25, 25, 0.3);

  & p {
    font-size: 14px;
    margin-bottom: ${({ theme }) => theme.spacing.two};
  }
`;

export const StyledOpenProgramsLearningMain = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  position: relative;
  height: calc(100% - 48px);
  margin-top: ${({ theme }) => theme.spacing.two};

  > div {
    & a {
      margin-top: ${({ theme }) => theme.spacing.two};
    }
  }
`;

export const StyledOpenProgramsCardQualifier = styled.p`
  color: ${({ theme }) => theme.colors.warmths.intensity};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: 14px;
`;

export const StyledOpenProgramsCardTitle = styled.p`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: 24px;
  margin-bottom: ${({ theme }) => theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 20px;
  }
`;

export const StyledOpenProgramsCardLink = styled.span`
  width: 100%;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.book};
  color: ${(props) => props.theme.colors.neutrals.black};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: inherit;

  & label {
    cursor: inherit;
  }
`;
