import styled from "styled-components";
interface ButtonProps {
    disabled?: boolean;
}

export const StyledFormStartupWrapper = styled.div`
    & div{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }    

    & label.titleField {
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    & input, select{
        border: 2px solid #a6a6a6;
        border-radius: 5px;
        padding: 8px 15px;
        font-size: 18px;
    }
    & input :focus-visible, select :focus-visible, textarea :focus-visible{
        border: 2px solid #e6007f;
        border-radius: 5px;
        padding: 8px 15px;
        font-size: 18px;
        outline: none;
    }
`;
export const StyledFormStartupSubmit = styled.button<ButtonProps>`
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    margin-top: 24px;
    background-color: #e6007f;
    border: 0px;
    padding: 10px 28px;
    color: white;
    border-radius: 20px;
    font-size: 17px;
    
    :disabled {
        opacity: 0.6;
        box-shadow: none;
    }
`;
export const StyledFormStartupMessage = styled.h4`
    margin-top: 5%;
    color: red;
    font-size: 17px;
`;