import React from "react";
import { optionSelect } from "../FormWrapper/FormCD";
import {
  StyledRadioContainer,
  StyledRadioWrapper,
  StyledRequired,
  StyledInvalidData
} from "./Partials";

interface RadioButtonProps {
  name: string;
  _uid: string;
  type: string;
  changeOptions: Function;
  defaultValue: any;
  required: boolean;
  options: optionSelect[];
  isValid: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  _uid,
  type,
  changeOptions,
  required,
  options,
  defaultValue,
  isValid,
}) => {
  return (
    <StyledRadioWrapper>
      {isValid === false &&
        (defaultValue.length > 0 ? (
          <StyledInvalidData>Invalid data entry</StyledInvalidData>
        ) : (
          <StyledRequired>This is required</StyledRequired>
        ))}
      {options.map(
        ({ label, value, _uid: _uidC, defaultValue: defaultValueR }, idx) => (
            <StyledRadioContainer key={`StyledContainerRadio-${idx}`}>
              <input
                type="radio"
                id={`${_uidC}`}
                name={name}
                value={value}
                checked={defaultValue === value ? true : Boolean(defaultValueR)}
                onChange={($event) => {
                  changeOptions($event, name, _uid, type);
                }}
                required={required}
              />
              <label htmlFor={`${_uidC}`}>{label}</label>
            </StyledRadioContainer>
        )
      )}
    </StyledRadioWrapper>
  );
};