import { FormFields, optionSelect } from "../FormCD";
import { configHeadersFormFields } from "./InitialFields";

export const getOptions = async (
    dataSourceCrr: FormFields[],
    configCrr: configHeadersFormFields
): Promise<FormFields[]> => {
    return new Promise(async (resolve) => {
        const dataSources = dataSourceCrr.filter(
            ({ datasource, name }) =>
                (datasource && configCrr.headers.name === name));

        for (let crr of dataSources) {
            const index = dataSourceCrr.findIndex(
                ({ _uid: id }: any) => id === crr._uid
            );

            const res: any = await fetch(crr.datasource, configCrr)
                .then((resp: any) => resp.json())
                .then((resp: any) => {
                    if (resp.hasOwnProperty('datasource_entries')) {
                        resp = resp.datasource_entries.map((country: any) => {
                            country.label = country.name;
                            return country;
                        });
                    }

                    return resp;
                })
                .catch((err) => {
                    return [];
                });

            let options: optionSelect[];

            options = [...res, ...crr.options.filter(({ value }) => value === 'Other')]

            dataSourceCrr[index] = { ...crr, options }
        }

        resolve(dataSourceCrr);
    });
};