import * as React from "react";
import { StyledCookie, StyledCookieHeader, StyledCookieBody } from "./partials";
import { render } from "storyblok-rich-text-react-renderer";

import { sbRichText } from "../../types";
import CookieContext from "../../context/CookieContext";
import { Icon } from "../Icons";

interface ICookieProps {
  cookie_title?: string;
  cookie_notice?: sbRichText;
}

const Cookie: React.FunctionComponent<ICookieProps> = ({
  cookie_title,
  cookie_notice,
}) => {
  const { data, set } = React.useContext(CookieContext);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setLoaded(true);
    }
  }, []);

  return (
    <>
      {!data?.cookieClose && loaded && (
        <StyledCookie
          onClick={() => {
            set && set({ cookieClose: true });
          }}
        >
          <StyledCookieHeader>
            <p>{cookie_title}</p>
            <Icon
              icon="times"
              onClick={() => {
                set && set({ cookieClose: true });
              }}
            />
          </StyledCookieHeader>

          <StyledCookieBody>
            <>{render(cookie_notice)}</>
          </StyledCookieBody>
        </StyledCookie>
      )}
    </>
  );
};

export default Cookie;
