import * as React from "react";
import CookieCmp from "./Cookie";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";

export const Cookie = () => {
  const { settings } = React.useContext(SettingsContextHooks);

  return (
    <CookieCmp
      cookie_title={settings?.content?.cookie_title}
      cookie_notice={settings?.content?.cookie_notice}
    />
  );
};
