import styled from "styled-components";

export const StyledEmbeddedForm = styled.div`
 
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  margin: 0 auto;
  
  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledEmbeddedFormTitle = styled.h2`
  margin: 0 0 24px;
  width: 58%;
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    font-size: 24px;
  }
`;

export const StyledEmbeddedFormContent = styled.section`
 
  font-weight: ${(props) => props.theme.font.light};

  & p {
    font-weight: ${(props) => props.theme.font.light};
    line-height: 24px;
  }
  
  & a {
    text-decoration: underline;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
