import styled from "styled-components";
import { brandColors } from "../../../types";

interface StyledBodyCenteredWModalProps {
  cta_colour?: brandColors;
}

export const StyledBodyCenteredWModal = styled.div<StyledBodyCenteredWModalProps>`
  padding: ${(props) => props.theme.spacing.eight} 0 ${(props) => props.theme.spacing.four} 0;
  margin: 0 auto;
  text-align: center;

  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
    width: calc(100% - 64px);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
    width: calc(100% - 64px);
  }
`;

export const StyledBodyCenteredWModalHeader = styled.h2`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 48px;
  max-width: 632px;
  line-height: 56px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing.four} auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
    margin: 0 auto ${(props) => props.theme.spacing.two} 0;
    max-width: 100%;
    line-height: 40px;
  }
`;
