import styled from "styled-components";

export const StyledRadioWrapper = styled.div`
   width: 100%;
`;

export const StyledRadioContainer = styled.div`
    margin:0 !important;
    flex-direction: row !important;
    margin-top: 5px !important;

    & input {
        padding: 0 !important;
        margin: 0 5px 0 0 !important;
    }

    & input[type='radio'] {
        accent-color: #e6007f;
        width:auto !important;

       @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
            width:auto !important;
        }
    }
`;