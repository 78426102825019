import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  CompetitionTimelineCmp,
  CompetitionTimelineCmpProps,
} from "./CompetitionTimeline";

interface CompetitionTimelineProps {
  blok: CompetitionTimelineCmpProps & blokType;
}

export const CompetitionTimeline: React.FC<CompetitionTimelineProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <CompetitionTimelineCmp
        title={blok.title}
        subtitle={blok.subtitle}
        steps={blok.steps}
      ></CompetitionTimelineCmp>
    </SbEditable>
  );
};
