import React from "react";
import { sbAssetType } from "../../types";

import { SbImage } from "hult-sb-ui";

import {
  StyledClientLogosCard,
  StyledClientLogosCardImgContainer,
} from "./partials";

export interface ClientLogosCardProps {
  logo: sbAssetType;
}

export const ClientLogosCard: React.FC<ClientLogosCardProps> = ({ logo }) => {
  return (
    <StyledClientLogosCard>
      <StyledClientLogosCardImgContainer>
        <SbImage image={logo} loading="lazy" resize="200x0" />
      </StyledClientLogosCardImgContainer>
    </StyledClientLogosCard>
  );
};
