import React from "react";
import { StyledInput } from "./Partials";

interface OtherProps {
  name: string;
  type: string;
  _uid: string;
  defaultValue: string;
  required: boolean;
}

export const Other: React.FC<OtherProps> =
  ({ name, type, defaultValue, required, _uid }) => {
    return (
      <StyledInput
        name={name}
        id={_uid}
        type={type}
        placeholder={defaultValue}
        defaultValue={defaultValue?.includes("Other") ? "" : defaultValue}
        required={required}
      />
    );
  };