import React from "react";
import { library, config } from "@fortawesome/fontawesome-svg-core";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { StyledIcon } from "./partials";

config.autoAddCss = false;

library.add(fas);
library.add(fab);

interface IIcon {
  icon: any;
  lib?: "fas" | "fab";
  onClick? : () => void
}

export const Icon: React.FC<IIcon> = ({ icon, lib = "fas" }) => {
  return <StyledIcon icon={[lib, icon]} />;
};
