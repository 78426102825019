import styled from "styled-components";

export const StyledMain = styled.div`
  position: absolute;
  top: 100px;
  left: 0;

  width: 100%; /* viewport width */
  height: 100vh; /* viewport height */
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    top: 90px;
  }
`;
