import React from "react";
import { brandColors, brandPink } from "../../types";
import { StyledButton } from "./partials";
import { sbLinkType } from "../../types";

interface ButtonProps {
  as?: "a" | "button";
  label?: string;
  color?: brandColors | brandPink;
  variant?: "primary" | "secondary";
  onClick?: any;
  href?: string | sbLinkType ;
  disabled?: boolean;
  parentComponent?: string;
}

export const ButtonHEF: React.FC<ButtonProps> = ({
  as = "button",
  label,
  color,
  onClick,
  href,
  disabled = false,
  variant = "primary",
  parentComponent = "undefined",
}) => {
  const url =
    typeof href === "string"
      ? href
      : href?.url
      ? href?.url
      : `/${href?.cached_url}`;
  function handleHref() {
    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: parentComponent,
        p_two: label,
        p_three: href,
      },
    });

    if (typeof window !== "undefined" && href) {
      window.location.href = url;
    }
  }

  function handleClick() {
    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: parentComponent,
        p_two: label,
      },
    });

    onClick();
  }

  return (
    <StyledButton
      color={color}
      disabled={disabled}
      variant={variant}
      onClick={as === "a" ? (_) => handleHref() : handleClick}
    >
      {label}
    </StyledButton>
  );
};
