import React, { useState } from "react";
import { StyledTelephoneInput, StyledDropdown, StyledInput, StyledRequired, StyledInvalidData } from "./Partials";
import { optionSelect } from "../FormWrapper/FormCD";

interface TelProps {
  name: string;
  type: string;
  _uid: string;
  defaultValue: any;
  required: boolean;
  changeOptions: Function;
  isValid: boolean;
  options: optionSelect[];
}

export const Tel: React.FC<TelProps> =
  ({ name, type, defaultValue, required, _uid, options, changeOptions, isValid }) => {

    const [codeCountry, setCodeCountry] = useState("");
    const [numberCountry, setNumberCountry] = useState("");
    const [numberCodeCountry, setNumberCodeCountry] = useState(defaultValue);

    const handleCodeCountryChange = ($event: any) => {
      setCodeCountry($event.target.value);
      setNumberCodeCountry($event.target.value + numberCountry);
      changeOptions(
        {
          target: {
            value: $event.target.value + numberCountry,
          },
        },
        name,
        _uid,
        type
      );
    };

    const handleNumberCountryKeyUp = ($event: any) => {
      if ($event.which != 8 && isNaN(+String.fromCharCode($event.which))) {
        setNumberCountry($event.target.value);
        setNumberCodeCountry(codeCountry + $event.target.value);
        const { validity: { valid } } = $event.target;
        changeOptions(
          {
            target: {
              value: codeCountry + $event.target.value,
            },
          },
          name,
          _uid,
          type,
          valid
        );
      }
    };

    return (
      <>
        {isValid === false &&
          (numberCodeCountry.length > 0 ? (
            <StyledInvalidData>Invalid data entry</StyledInvalidData>
          ) : (
            <StyledRequired>This is required</StyledRequired>
          ))}

        <StyledDropdown
          onChange={handleCodeCountryChange}
          required={required}
        >
          {options.map(({ label, value, id }, idx) => (
            <option
              id={`${id ?? "Other"}`}
              key={`StyleSelect-${idx}`}
              value={value}
            >
              {label}
            </option>
          ))}
        </StyledDropdown>
        <StyledTelephoneInput
          id={_uid}
          type={type}
          pattern="[0-9.]+"
          onChange={handleNumberCountryKeyUp}
          required={required}
        />
        <StyledInput
          defaultValue={numberCodeCountry}
          id={`custom-${name}`}
          name={name}
          type="hidden"
          required={required}
        />
      </>
    );
  };
