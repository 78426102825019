import styled from "styled-components";

export const StyledFormCDWrapper = styled.form`
    & div{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }    

    & label.titleField {
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    & label.titleFieldCustom {
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    & input, select{
        border: 2px solid #a6a6a6;
        border-radius: 5px;
        padding: 8px 15px;
        font-size: 18px;
    }
    & input :focus-visible, select :focus-visible, textarea :focus-visible{
        border: 2px solid #e6007f;
        border-radius: 5px;
        padding: 8px 15px;
        font-size: 18px;
        outline: none;
    }
`;

export const StyledFormCDWrapperSubmit = styled.button`
    margin-top: 24px;
    background-color: #e6007f;
    border: 0px;
    padding: 10px 28px;
    color: white;
    border-radius: 20px;
    font-size: 17px;
    position: absolute;
    cursor: pointer;
`;