import React from "react";
import { sbAssetType } from "../../types";
import {
  StyledHeroHome,
  StyledHeroHomeWrapper,
  StyledHeroHomeBackground,
  StyledHeroHomeTitle,
  StyledHeroHomeBlurPink,
  StyledHeroHomeBlurYellow,
} from "./partials";

import { SbImageBackgroundService } from "../utils/helpers";

export interface HeroHomeProps {
  title: string | any;
  background_image?: sbAssetType;
}

export const HeroHomeCmp: React.FC<HeroHomeProps> = ({ title, background_image }) => {
  return (
    <StyledHeroHome
      id={title.toLowerCase().replace(/ /g, "-")}
      baImage={background_image?.filename}
    >
      <StyledHeroHomeBackground
        style={{
          backgroundImage: `url("${SbImageBackgroundService(
            background_image && background_image?.filename
          )}")`,
        }}
      >
        <StyledHeroHomeBlurPink></StyledHeroHomeBlurPink>
        <StyledHeroHomeBlurYellow></StyledHeroHomeBlurYellow>
      </StyledHeroHomeBackground>

      <StyledHeroHomeWrapper>
        <StyledHeroHomeTitle>{title}</StyledHeroHomeTitle>
      </StyledHeroHomeWrapper>
    </StyledHeroHome>
  );
};