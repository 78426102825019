import styled from "styled-components";

export const StyledBodyContainer = styled.main`
  top: 0;
  left: 0;
  position: relative;
  height: auto;
  width: 100%;
  overflow: auto;
`;

export const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing.seven};
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: ${(props) => props.theme.spacing.four};
  }
`;
