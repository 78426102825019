require("dotenv").config();

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
// const siteAddress = new URL(process.env.GATSBY_HOST);

module.exports = {
  siteMetadata: {
    siteUrl: "https://www.yourdomain.tld",
    title: "HultPrize",
    description: "Hult Prize",
    author: "EF Global Creative",
  },
  plugins: [
    {
      resolve: `gatsby-source-storyblok`,
      options: {
        accessToken: process.env.GATSBY_SB_PREVIEW_TOKEN,
        homeSlug: `/`,
        version: `${activeEnv === "production" ? "published" : "draft"}`,
        resolveRelations: [],
        // includeLinks: true,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    "gatsby-plugin-styled-components",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Hult Prize`,
        short_name: `Hult Prize`,
        start_url: `/`,
        background_color: `#FFFFFF`,
        theme_color: `#FFFFFF`,
        display: `minimal-ui`,
        icon: `src/assets/images/favicon.png`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        output: `/`,
        excludes: [`/editor`, `404`],
      },
    },
    {
      resolve: `gatsby-plugin-robots-txt`,
      options: {
        host:
          process.env.GATSBY_APP_ENV === `PROD`
            ? process.env.GATSBY_HOST
            : undefined,
        sitemap: [`${process.env.GATSBY_HOST}/sitemap-index.xml`],
        policy: [
          {
            userAgent: `*`,
            disallow:
              process.env.GATSBY_APP_ENV === `PROD`
                ? [
                    `/editor`,
                    `/fscallback`,
                    `/*/templates/`,
                    `/*/landing/`,
                    `/*/lp/`,
                    `/*/_config/`,
                  ]
                : [`/`],
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: process.env.GATSBY_TAG_MANAGER_ID,
        includeInDevelopment: true,
      },
    },
  ],
};
