import React, { useEffect } from "react";
import { GlobalNavigation } from "../components/GlobalNavigation";
import { Footer } from "../components/Footer";
import SettingsContextHooksProvider from "../context/SettingsContextHooks";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/theme";
import { GlobalStyle } from "../styles/globalStyle";
import { StyledMain } from "./partials";
import { Cookie } from "../components/Cookie";
// Polyfill for smooth scroll to fix safari, edge and IE
// @ts-ignore
import smoothscroll from "smoothscroll-polyfill";

interface ILayoutProps {
  children?: React.ReactNode;
  settings?: {};
  pageContext? : {};
}

const Layout: React.FC<ILayoutProps> = ({ children, settings = {} }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <>
      <SettingsContextHooksProvider settings={settings}>
        <ThemeProvider theme={theme}>
          <StyledMain>
            <GlobalStyle />
            <GlobalNavigation />
            {children}
            <Footer />
          </StyledMain>
          <Cookie />
        </ThemeProvider>
      </SettingsContextHooksProvider>
    </>
  );
};

export default Layout;
