import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    overflow: auto;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    height: 100%;
    overflow: none;
    font-family: 'Poppins', sans-serif;
  }

  h1 {
    font-size: 32px;
    margin: 0;
  }

  h2, h3, h4, h5, h6 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  a:hover {
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  & hr {
    border: none;
    height: 1px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  & p {
    &:nth-of-type(1){
      margin-block-start: 0;
    }
    &:nth-last-of-type(1){
      margin-block-end: 0;
    }
  }

  & button {
    font-family: 'Poppins', sans-serif;
  }
`;
