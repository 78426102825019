import React from "react";
import { optionSelect } from "../FormWrapper/FormCD";
import { StyledSelect, StyledRequired, StyledInvalidData } from "./Partials";

interface SelectProps {
  name: string;
  _uid: string;
  type: string;
  changeOptions: Function;
  defaultValue: any;
  required: boolean;
  options: optionSelect[];
  isValid: boolean;
}

export const Select: React.FC<SelectProps> =
  ({ name, _uid, type, changeOptions, defaultValue, required, options, isValid }) => {
    return (
      <>
        {isValid === false &&
          (defaultValue.length > 0 ? (
            <StyledInvalidData>Invalid data entry</StyledInvalidData>
          ) : (
            <StyledRequired>This is required</StyledRequired>
          ))}
        <StyledSelect
          onChange={($event: any) => {
            changeOptions($event, name, _uid, type);
          }}
          name={`${defaultValue}`?.includes("Other") ? "" : name}
          id={name}
          value={defaultValue}
          required={required}
        >
          {options.map(({ label, value, id }, idx) => (
            <option
              id={`${id ?? "Other"}`}
              key={`StyleSelect-${idx}`}
              value={value}
            >
              {label}
            </option>
          ))}
        </StyledSelect>
      </>
    );
  };