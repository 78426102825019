import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledIcon = styled(FontAwesomeIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 14px;
  min-width: 14px;
  max-height: 14px;
  min-height: 14px;
`;
