import React from "react";
import { sbAssetType } from "../../types";

import {
  StyledQuote,
  StyledQuoteWrapper,
  StyledQuoteText,
  StyledQuoteAuthorWrapper,
  StyledQuoteAuthor,
  StyledQuoteAuthorPic,
  StyledQuoteCompanyLogo,
} from "./partials";

export interface QuoteProps {
  quote: string;
  author?: string;
  author_picture?: sbAssetType;
  company_logo?: sbAssetType;
  article?: boolean;
}

export const QuoteCmp: React.FC<QuoteProps> = ({
  quote,
  author,
  author_picture,
  company_logo,
  article = false,
}) => {
  return (
    <StyledQuote>
      <StyledQuoteWrapper article={article}>
        <StyledQuoteText>{quote}</StyledQuoteText>
        <StyledQuoteAuthorWrapper>
          {author_picture?.filename && author && (
            <StyledQuoteAuthorPic
              alt={author_picture.alt}
              src={author_picture?.filename}
              title={author_picture?.title}
            ></StyledQuoteAuthorPic>
          )}
          {author && <StyledQuoteAuthor>{author}</StyledQuoteAuthor>}
          {company_logo?.filename && !author && (
            <StyledQuoteCompanyLogo
              alt={company_logo.alt}
              src={company_logo?.filename}
              title={company_logo?.title}
            ></StyledQuoteCompanyLogo>
          )}
        </StyledQuoteAuthorWrapper>
      </StyledQuoteWrapper>
    </StyledQuote>
  );
};
