import styled from "styled-components"

export const StyledForm = styled.div`
    max-width: ${(props) => props.theme.maxWidth};
    margin: 0 auto;
    border: 2px solid #a6a6a6;
    padding: 0px 45px 45px 45px;
    border-radius: 15px;
    margin-bottom: 25px;
    
    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      padding: 0px 30px 30px 30px;
    }
`;
