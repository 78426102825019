import styled from "styled-components";

export const StyledSNumbIndicator = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
`;
export const StyledSNumbIndicatorImg = styled.img`
  max-height: 50px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 90px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: auto;
  }
`;
export const StyledSNumbIndicatorCountUp = styled.div`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`;
export const StyledSNumbIndicatorContainer = styled.div`
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  
    & span{
        font-size: 27px;   
        border-top: 1px solid ${(props) => props.theme.colors.warmths.pink}; 
        margin-bottom: -10px;
        padding-top 8px;
        font-weight: ${(props) => props.theme.font.bold}; 
    }
`;
export const StyledSNumbIndicatorText = styled.p`
  font-weight: ${(props) => props.theme.font.light};
  font-size: 16px;
  margin-top: -7px;
`;
export const StyledHeaderAssetWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-basis: 100%;
    margin: 0 auto;
    margin-bottom: 0;
  }
`;
