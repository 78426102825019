import React from "react";
import { brandColors } from "../../types";

import {
  StyledBulletPointsItem,
  StyledBulletPointsItemTextWrapper,
  StyledBulletPointsItemTextTitle,
  StyledBulletPointsItemTextDescription,
} from "./partials";

export interface BulletPointsItemProps {
  _uid?: string;
  title?: string;
  description: string;
  bullet_colour?: brandColors;
}

export const BulletPointsItem: React.FC<BulletPointsItemProps> = ({
  title,
  description,
  bullet_colour,
}) => {
  return (
    <StyledBulletPointsItem bullet_color={bullet_colour}>
      •
      <StyledBulletPointsItemTextWrapper>
        {title && (
          <StyledBulletPointsItemTextTitle>
            {title}
          </StyledBulletPointsItemTextTitle>
        )}
        <StyledBulletPointsItemTextDescription>
          {description}
        </StyledBulletPointsItemTextDescription>
      </StyledBulletPointsItemTextWrapper>
    </StyledBulletPointsItem>
  );
};
