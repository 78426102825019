import React, { useEffect, useState } from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import {
    StyledFormCIWrapper,
    StyledFormCISubmit,
    StyledFormMessage
} from "./Partials";
import {
    INITIAL_FIELDS,
    headersSelect,
} from "./Helpers/InitialFields";
import { getOptions } from "./Helpers/FormFields.actions";
import { FormFieldsCustom } from "./FormFieldCustom";
import { optionSelect, FormFields } from "./FormCD";


interface IFormCIBlok {
    fields: FormFields[];
    submit: string;
    action: string;
}

interface FormCIProps {
    blok: IFormCIBlok & blokType;
}

export const FormCI: React.FC<FormCIProps> = ({ blok }) => {
    const { fields, submit, action } = blok;

    const [formFields, setFormFields] = useState<any>([]);
    const { config } = INITIAL_FIELDS;
    const [loaded, setLoaded] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const changeOptions = async (
        event: any,
        name: string,
        _uid: string,
        type: string,
        valid: boolean,
        id: number | string
    ) => {
        const {
            target: { value },
        } = event;

        let dataSource = formFields;

        const indexPattern = dataSource.findIndex(
            ({ options, _uid: _uidC }: FormFields) =>
                options.length
                    ? options.some(({ value: valueC }) => valueC === value) &&
                    _uidC === _uid
                    : _uidC === _uid
        );

        let elementPattern: FormFields = dataSource[indexPattern];

        const indexChildren = elementPattern.options.findIndex(
            ({ value: valueC }) => valueC === value
        );

        type === "radio" &&
            (elementPattern.options = elementPattern.options.map((option) => ({
                ...option,
                defaultValue: "",
            })));

        let elementChildren: optionSelect = elementPattern.options[indexChildren];

        let defaultValue = event.target.hasOwnProperty("checked")
            ? event.target.checked
                ? value
                : ""
            : value;

        elementPattern.defaultValue = defaultValue;

        elementChildren && (elementChildren.defaultValue = defaultValue);
        if (elementPattern.isValid) {
            elementPattern.isValid = valid;
        } else {
            elementPattern = {
                ...elementPattern,
                isValid: valid
            };
        }



        dataSource[indexPattern] = elementPattern;

        dataSource = dataSource.map((field: FormFields) => {
            let { defaultValue } = field;

            return { ...field, defaultValue };
        });

        const { DependeField } = elementPattern;

        const headers: headersSelect = {
            action: name,
            Location: elementChildren?.value ?? elementPattern.value,
            name: DependeField,
        };

        const fieldsAPI = await getOptions(dataSource, { ...config, headers });

        setFormFields(fieldsAPI);
    };

    const getFieldsAPI = async () => {
        const formFieldsAPI = await getOptions(fields, config);
        setFormFields(formFieldsAPI);
    };

    useEffect(() => {
        getFieldsAPI();
    }, []);

    const validationAnswer = ({
        DependeField,
        operator,
        value: valueM,
        Action,
    }: FormFields) => {
        let resultValidation = true;
        if (Boolean(DependeField) && Action !== "call-datasource") {
            let fieldPattern: FormFields;
            fieldPattern = formFields.find(
                ({ name }: any) => name === DependeField
            );

            const { defaultValue: dependantValueDefault, options } = fieldPattern;

            resultValidation =
                eval(`'${dependantValueDefault}' ${operator} '${valueM}'`) ||
                (operator === "==" &&
                    (options.length
                        ? options.some(
                            ({ defaultValue }) =>
                                defaultValue && valueM.includes(defaultValue) && !valueM
                        )
                        : dependantValueDefault && valueM.includes(dependantValueDefault) && !valueM));
        }

        return resultValidation;
    };

    const checkValidity = (): boolean => {
        let flag = true;
        let checkedFields = [...formFields];

        checkedFields = checkedFields.map((f: FormFields) => {
            if (f.required && f.type != 'hidden' && f.defaultValue == '') {

                if (f.isValid) {
                    f.isValid = false;
                } else {
                    flag = false;
                    f = {
                        ...f,
                        isValid: false
                    };
                }
            }else if(f.required && f.type != 'hidden' && f.defaultValue != '' && f.isValid == false){
                flag = false;
            }
            return f;
        })
        setFormFields(checkedFields);
        return flag;
    }

    async function handleSubmit() {

        let validt = checkValidity();
        if (validt) {
            setLoaded(true);
            setButtonDisabled(true);
            setLoaderMessage('Saving your application!');
            let json: any = {}
            formFields.forEach((f: FormFields) => {
                json[f.name] = f.defaultValue;

            })

            fetch(`${action}`, {
                method: "put",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    json,
                ),
            })
                .then((result) => {
                    setLoaderMessage("Application Submitted!");
                    setFormFields([]);
                    setFormFields(fields);
                    setButtonDisabled(false);
                    return result.json();
                })
                .catch((err) => {
                    setLoaderMessage("Error while trying to save the application. Try again later.")
                });
        }else{
            setError(true);
            setErrorMessage("There are some erros in the Form.");     
        }
    }

    return (
        <SbEditable content={blok}>
            <StyledFormCIWrapper>
                <div>
                    {formFields && formFields
                        .filter((field: FormFields) => validationAnswer(field))
                        .map((field: FormFields, index: number) => {
                            return (
                                <FormFieldsCustom
                                    key={`form-fields-custom-${field._uid}-${index}`}
                                    field={field}
                                    formFields={formFields}
                                    index={index - 1}
                                    changeOptions={changeOptions}
                                />
                            );
                        })}
                </div>
                <StyledFormMessage>
                {error && errorMessage}
                </StyledFormMessage>
                <StyledFormCISubmit disabled={isButtonDisabled} onClick={handleSubmit} >
                    {loaded ? loaderMessage : submit}
                </StyledFormCISubmit>

            </StyledFormCIWrapper>
        </SbEditable >
    );
};