import styled from "styled-components";

export const StyledTelephoneWrapper = styled.div`
    width:100%;
    display: block !important;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        display: grid !important;
    }
`;

export const StyledTelephoneInput = styled.input`
   margin-top:15px;
   width:50%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        margin-top:15px;
        width:auto;
    }
`;