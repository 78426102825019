import React from "react";
import SbEditable from "storyblok-react";

import { BaseComponentProps, IDictionary } from "../types/index";

import ComponentNotFound from "./ComponentNotFound/index";

// --- Local components ---
import Page from "./Templates/Page/page";
import { BodyOne } from "./Header/BodyOne";
import { Header } from "./Header";
import { HeaderWithBackground } from "./HeaderWithBackground";
import { HighlightedList } from "./HighlightedList";
import { BodyCenteredWithCTA } from "./BodyCenteredWithCTA";
import { BodyCopy } from "./BodyCopy";
import { BulletPoints } from "./BulletPoints";
import { Quote } from "./Quote";
import { ClientLogos } from "./ClientLogos";
import { ContactUs } from "./ContactUs/ContactUs";
import { ContactUsSC } from "./ContactUs/ContactUsSC";
import { DescriptionWithImage } from "./DescriptionWithimage";
import { FullBleedImage } from "./FullBleedImage";
import { OverviewHeader } from "./OverviewHeader";
import { ArticleOverview } from "./StoriesOverview";
import { FacultyCarousel } from "./FacultyCarousel";
import { CompetitionTimeline } from "./CompetitionTimeline";
import { TimelineStep } from "./CompetitionTimeline/TimelineStep";
import { EmbeddedForm } from "./EmbeddedForm";
import { FormWrapper } from "./FormWrapper";
import { ExpertsWithLinks } from "./ExpertsWithLinks";
import { HeroHomePage } from "./HeroHomePage";
import { StatisticalNumber } from "./StatisticalNumber";
import MetaData from "./MetaData";

const ComponentList: IDictionary<React.FC<any>> = {
  page: Page,
  post: Page,
  "body-copy": BodyCopy,
  "body-one": BodyOne,
  "bullet-points": BulletPoints,
  "body-centered-with-cta": BodyCenteredWithCTA,
  "client-logos": ClientLogos,
  "contact-us": ContactUs,
  "contact-us-sc": ContactUsSC,
  "description-with-image": DescriptionWithImage,
  "full-bleed-image": FullBleedImage,
  "form-wrapper": FormWrapper,
  header: Header,
  "highlighted-list": HighlightedList,
  "header-with-background": HeaderWithBackground,
  "hero-homepage": HeroHomePage,
  quote: Quote,
  "overview-header": OverviewHeader,
  "meta-data": MetaData,
  "stories-overview": ArticleOverview,
  "statistical-numbers": StatisticalNumber,
  "faculty-carousel": FacultyCarousel,
  "competition-timeline": CompetitionTimeline,
  "timeline-step": TimelineStep,
  "embedded-form": EmbeddedForm,
  "experts-with-links": ExpertsWithLinks,
};

const DynamicComponent: React.FC<BaseComponentProps> = ({
  blok,
  path,
  content,
  story,
  settings,
  alternates,
}) => {
  if (typeof ComponentList[blok.component] !== "undefined") {
    const Component = ComponentList[blok.component];
    return (
      <SbEditable content={blok}>
        <Component
          blok={blok}
          path={path}
          content={content}
          story={story}
          settings={settings}
          alternates={alternates}
        />
      </SbEditable>
    );
  }
  return <ComponentNotFound blok={blok} />;
};

export default DynamicComponent;
