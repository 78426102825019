import React from "react";
import { sbAssetType } from "../../types";
import ReactPlayer from "react-player";
import { SbImage, sbImageService } from "hult-sb-ui";
import { Icon } from "../Icons";
import { render } from "storyblok-rich-text-react-renderer";
import {
  StyledFullBleedImgContainer,
  StyledFullBleedImg,
  StyledFullBleedGrapicOverlay,
  StyledIconPlayWrapper,
  StyledVideoWrapper,
  StyledFullBleedTitle,
  StyledFullBleedDescription,
} from "./partials";

export interface FullBleedImageProps {
  image: sbAssetType;
  component?: string;
  graphic_overlay?: sbAssetType;
  graphic_overlay_mobile?: sbAssetType;
  graphic_overlay_placement?: string;
  video?: sbAssetType;
  video_preview?: sbAssetType;
  asset_link?: string;
  is_square?: boolean;
  title?: string;
  body?: any;
}
const reactPlayerInline = {
  borderRadius: "4px",
};
export const FullBleedImageCmp: React.FC<FullBleedImageProps> = ({
  image,
  component,
  video,
  video_preview,
  asset_link,
  graphic_overlay,
  graphic_overlay_mobile,
  is_square,
  title,
  body,
  graphic_overlay_placement = "bottom",
}) => {
  return (
    <>
    {title && <StyledFullBleedTitle>{title}</StyledFullBleedTitle>}
     {body && <StyledFullBleedDescription>{render(body)}</StyledFullBleedDescription>}
      
    <StyledFullBleedImgContainer id={component}>
      <StyledFullBleedImg>
      {!video?.filename && !asset_link &&(
          <SbImage image={image} loading="lazy" resize="1200x0" />
        )}
        {/* If image available only show image */}
        {video?.filename && video_preview?.filename && (
          <StyledVideoWrapper square={is_square}>
            <ReactPlayer
              url={video.filename}
              light={sbImageService(video_preview.filename)}
              width="100%"
              height="100%"
              playing={true}
              style={reactPlayerInline}
              playIcon={
                <StyledIconPlayWrapper>
                  <Icon icon="play" />
                </StyledIconPlayWrapper>
              }
              controls={true}
            />
             </StyledVideoWrapper>
          )}

          {/* If image or video available dont show iframe */}
          {asset_link && (!video?.filename) && (
            <StyledVideoWrapper square={is_square}>
            <ReactPlayer
              url={asset_link}
              light={video_preview?.filename ? video_preview.filename : true}
              width="100%"
              height="100%"
              style={reactPlayerInline}
              playIcon={
                <StyledIconPlayWrapper>
                  <Icon icon="play" />
                </StyledIconPlayWrapper>
              }
              playing={true}
              controls={true}
            />
             </StyledVideoWrapper>
          )}
        {graphic_overlay?.filename && (
          <StyledFullBleedGrapicOverlay
            src={sbImageService(
              graphic_overlay.filename,
              undefined,
              "1200x0",
              graphic_overlay.focus
            )}
            placement={graphic_overlay_placement}
            alt={graphic_overlay.alt}
            title={graphic_overlay.alt}
          ></StyledFullBleedGrapicOverlay>
        )}
        {graphic_overlay_mobile?.filename && (
          <StyledFullBleedGrapicOverlay
            src={sbImageService(
              graphic_overlay_mobile.filename,
              undefined,
              "780x0"
            )}
            mobile
            placement={graphic_overlay_placement}
            alt={graphic_overlay_mobile.alt}
            title={graphic_overlay_mobile.alt}
          ></StyledFullBleedGrapicOverlay>
        )}
      </StyledFullBleedImg>
    </StyledFullBleedImgContainer>
    </>
    
  );
};
