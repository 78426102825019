import React from "react";
import { brandColors, sbLinkType } from "../../types";
import { Icon } from "../Icons";
import { getUrl } from "../utils/helpers";

import {
  StyledContactUsCard,
  StyledContactUsCardTitle,
  StyledContactUsCardBody,
  StyledContactUsCardA,
} from "./partials";

export interface ContactUsCardProps {
  title: string;
  body: string;
  component: string;
  brand_colour?: brandColors;
  tel?: string;
  link?: sbLinkType;
}

export const ContactUsCard: React.FC<ContactUsCardProps> = ({
  title,
  body,
  component,
  brand_colour,
  tel,
  link,
}) => {
  function handleClick() {
    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: component,
        p_two: title,
      },
    });
  }

  return (
    <StyledContactUsCardA
      href={tel ? `tel:${tel}` : link ? getUrl(link) : undefined}
      onClick={(_) => handleClick()}
      target={link?.url ? "_blank" : "_self"}
    >
      <StyledContactUsCard brand_color={brand_colour}>
        <StyledContactUsCardTitle>{title}</StyledContactUsCardTitle>
        <StyledContactUsCardBody>
          <p>{body}</p>
          <Icon icon="arrow-right" />
        </StyledContactUsCardBody>
      </StyledContactUsCard>
    </StyledContactUsCardA>
  );
};
