import styled from "styled-components";

interface StyledHeroHomeWrapperProps {
  baImage?: string;
}

export const StyledHeroHome = styled.div<StyledHeroHomeWrapperProps>`
  padding: ${(props) => (props.baImage ? "0px" : props.theme.spacing.eight)} 0
    ${(props) => props.theme.spacing.seven} 0;
  margin-bottom: 3rem;
  height: ${(props) => (props.baImage ? "80vh" : "auto")};
  position: relative;
  padding: ${(props) => (props.baImage ? "0px" : props.theme.spacing.seven)}
    ${(props) => (props.baImage ? "0px" : props.theme.spacing.five)};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => (props.baImage ? "0px" : props.theme.spacing.seven)}
      ${(props) => (props.baImage ? "0px" : props.theme.spacing.five)};
    height: ${(props) => (props.baImage ? "82vh" : "auto")};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => (props.baImage ? "0px" : props.theme.spacing.four)}
      ${(props) => (props.baImage ? "0px" : props.theme.spacing.three)};
    height: ${(props) => (props.baImage ? "82vh" : "auto")};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    height: ${(props) => (props.baImage ? "80vh" : "auto")};
    margin-bottom: 0rem;
  }
`;

export const StyledHeroHomeWrapper = styled.div<StyledHeroHomeWrapperProps>`
  display: flex;
  justify-content: center;
  max-width: ${(props) => props.theme.maxWidth};
  margin: auto;
  padding: ${(props) => (props.baImage ? props.theme.spacing.eight : "0")} 0
    ${(props) => (props.baImage ? props.theme.spacing.seven : "0")} 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin: 10% auto 0;
    padding: ${(props) => (props.baImage ? props.theme.spacing.seven : "0")}
      ${(props) => (props.baImage ? props.theme.spacing.five : "0")};
    bottom: 1%;
    & h1 {
      font-size: 48px;
      margin-top: ${(props) => props.theme.spacing.two};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: auto;
    flex-direction: column-reverse;
    padding: ${(props) => (props.baImage ? props.theme.spacing.four : "0")}
      ${(props) => (props.baImage ? props.theme.spacing.three : "0")};
    bottom: 2%;
    & h1 {
      margin-top: ${(props) => props.theme.spacing.two};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    bottom: 8%;
    & h1 {
      font-size: 29px;
      margin-top: ${(props) => props.theme.spacing.four};
      line-height: 30px;
    }
  }
`;

export const StyledHeroHomeTitle = styled.h1`
  white-space: pre-line !important;
  font-size: 52px;
  margin-top: ${(props) => props.theme.spacing.two};
  text-align: center;
  color: white;
  line-height: 60px;
`;

export const StyledHeroHomeBackground = styled.div`
  clip-path: ellipse(120rem 53% at 48% 33%);
  min-height: 100vh;
  min-width: 90vw;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    clip-path: ellipse(90rem 50% at 48% 33%);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    clip-path: ellipse(90rem 56% at 48% 33%);
    min-width: 94vw;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    min-height: 94vh;
    clip-path: ellipse(60rem 48% at 48% 33%);
  }
`;

export const StyledHeroHomeBlurPink = styled.div`
  position: absolute;
  top: 98%;
  left: 40%;
  box-shadow: 20px -18px 75px 58px ${(props) => props.theme.colors.warmths.pink};
  width: 150px;
  height: 110px;
  background: ${(props) => props.theme.colors.warmths.pink};
  border-radius: 50%;
  z-index: 2;
  filter: blur(25px);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    left: 32%;
    top: 100%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    left: 28%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    left: 8%;
  }
`;
export const StyledHeroHomeBlurYellow = styled.div`
  position: absolute;
  top: 98%;
  left: 48%;
  box-shadow: 20px -18px 75px 58px #ffd400;
  width: 150px;
  height: 110px;
  background: #ffd400;
  border-radius: 50%;
  z-index: 1;
  filter: blur(25px);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    top: 100%;
  }
`;