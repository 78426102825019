import styled from "styled-components";

export const StyledInputWrapper = styled.div`
    width:100%;
`;

export const StyledInput = styled.input`
    width:50%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        width: auto !important;
    }
`;