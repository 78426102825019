import React from "react";

import {
  StyledBodyCenteredWModal,
  StyledBodyCenteredWModalHeader,
} from "./partials";
import { brandColors, sbLinkType } from "../../types";
import { getKey, getUrl } from "../utils/helpers";
import { ButtonHEF } from "../Button";

export interface BodyCenteredWithCTACmpProps {
  _uid: string;
  title: string;
  component: string;
  cta_label: string;
  cta_link?: sbLinkType;
  cta_colour?: brandColors;
}

export const BodyCenteredWithCTACmp: React.FC<BodyCenteredWithCTACmpProps> = ({
  _uid,
  title,
  component,
  cta_label,
  cta_link,
  cta_colour,
}) => {
  return (
    <>
      <StyledBodyCenteredWModal
        id={title.toLowerCase().replace(/ /g, '-')}
        key={getKey("body-centered-with-cta", _uid)}
        cta_colour={cta_colour}
      >
        <StyledBodyCenteredWModalHeader>{title}</StyledBodyCenteredWModalHeader>
        {cta_link && (
          <ButtonHEF
            as={"a"}
            variant="primary"
            parentComponent={component}
            href={getUrl(cta_link)}
            label={cta_label}
            color={cta_colour}
          ></ButtonHEF>
        )}
      </StyledBodyCenteredWModal>
    </>
  );
};
