import styled from "styled-components";

export const StyledPagination = styled.div`
  & .ef-pagination--lg .-is-active {
    background-color: rgba(214, 204, 191, 0.5);
  }

  & .ef-pagination--lg .ef-pagination--lg__page {
    cursor: pointer;
  }
`;

export const StyledPaginationContext = styled.div`
  margin-top: ${({ theme }) => theme.spacing.one};
  font-weight: ${({ theme }) => theme.font.light};
`;
