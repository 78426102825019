import styled from "styled-components";
import { StyledOverview, StyledOverviewCard, StyledOverviewCardAside } from ".";

export const StyledArticleOverview = styled(StyledOverview)``;

export const StyledArticleOverviewCard = styled(StyledOverviewCard)<{
  qualifier_colour?: string;
}>``;

export const StyledArticleOverviewCardAside = styled(StyledOverviewCardAside)``;
