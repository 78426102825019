import { FormFields } from "../FormCD";

export interface headersSelect {
  name?: string;
  Location?: string;
  action?: string;
}

export interface configHeadersFormFields {
  method: string;
  headers: headersSelect | any;
}

type FormFieldsInterface = {
  formFields: FormFields[];
  config: configHeadersFormFields;
};

export const INITIAL_FIELDS: FormFieldsInterface = {
  formFields: [],
  config: {
    method: "GET",
    headers: {
      Location: "",
      action: "country_code",
      name: "country_code",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  },
};