import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { EmbeddedFormCmp, EmbeddedFormProps } from "./EmbeddedForm";

interface EmbeddedFormWBlokProps {
  blok: EmbeddedFormProps & blokType;
}

export const EmbeddedForm: React.FC<EmbeddedFormWBlokProps> = ({ blok }) => (
  <SbEditable content={blok}>
    <EmbeddedFormCmp
      title={blok.title}
      component={blok.component}
      body={blok.body}
      brand_colour={blok.brand_colour}
    ></EmbeddedFormCmp>
  </SbEditable>
);
