import styled from "styled-components";
import { brandColors } from "../../../types";

interface StyledContactUsCardProps {
  brand_color?: brandColors;
}

export const StyledContactUsCardA = styled.a`
  border: 1px solid rgba(25, 25, 25, 0.2);
  border-radius: 8px;
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0;

  :hover {
    background-color: ${(props) => props.theme.colors.warmths.magenta};
  }
`;

export const StyledContactUsCard = styled.div<StyledContactUsCardProps>`
  height: auto;
  width: auto;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.four}!important;
  border: none;
  border-radius: inherit;
  color: ${({ theme }) => theme.colors.neutrals.black};

  & p {
    margin-right: 8px;
  }

  & svg {
    transition: transform 500ms;
    min-width: 14px;
    min-height: 14px;
  }

  :hover {
    background-color: ${(props) =>
      props.brand_color
        ? props.theme.colors.warmths[props.brand_color]
        : props.theme.colors.warmths.magenta};

    & p,
    svg {
      color: ${({ theme }) => theme.colors.neutrals.white};
    }

    & svg {
      transform: translateX(4px);
    }
  }
`;

export const StyledContactUsCardTitle = styled.p`
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: 16px;
  margin-bottom: 0;
`;

export const StyledContactUsCardBody = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-weight: ${({ theme }) => theme.font.light};
  font-size: 14px;

  margin-bottom: 0;

  & p {
    margin-bottom: 0;
    text-overflow: hidden;
    font-weight: ${({ theme }) => theme.font.light};
    font-size: 14px;
    line-height: 24px;
  }
`;
