import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { ContactUs, ContactUsProps } from "./ContactUs";

import { AllStoryblokEntryResult, blokType, storyType } from "../../types";

export interface ContactUsStoryProps extends storyType {
  content: blokType & ContactUsProps;
}

export interface ContactUsSCProps {
  contact_us: string;
}

export interface ContactUsQueryProps {
  blok: blokType & ContactUsSCProps;
}

export const ContactUsSC: React.FC<ContactUsQueryProps> = ({ blok }) => {
  function parseQueryResult(data: AllStoryblokEntryResult, id: string): any {
    let parsedResult: blokType | null = null;

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: blokType = node.content;
      if (typeof node.content === "string") content = JSON.parse(node.content);
      if (node.uuid == id) {
        parsedResult = content;
      }
    });

    return parsedResult;
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allStoryblokEntry(filter: { field_component: { eq: "contact-us" } }) {
            edges {
              node {
                uuid
                id
                content
              }
            }
            totalCount
          }
        }
      `}
      render={(data) => {
        const parsedResult = parseQueryResult(data, blok.contact_us);
        return (
          <>
            {parsedResult && (
              <ContactUs
                _uid={parsedResult._uid}
                component={parsedResult.component}
                title={parsedResult.title}
                description={parsedResult.description}
                cards={parsedResult.cards}
                brand_colour={parsedResult.brand_colour}
              ></ContactUs>
            )}
          </>
        );
      }}
    />
  );
};
