import styled from "styled-components";

interface StyledDescWImageCardBodyProps {
  linkBottom?: boolean;
}

export const StyledDescWImageCard = styled.div`
position: relative;
`;

export const StyledDescWImageCardImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 310px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);
  background-color: #ffffff;
`;

export const StyledDescWImageCardTitle = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  margin: ${(props) => props.theme.spacing.two} 0
    ${(props) => props.theme.spacing.one} 0;
`;

export const StyledDescWImageCardBody = styled.div<StyledDescWImageCardBodyProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: ${(props) => props.theme.font.light};
  text-overflow: ellipsis;
  margin-bottom: ${(props) =>
    props.linkBottom ? props.theme.spacing.two : "0"};
`;

export const StyledSocialBar = styled.div`
  display: flex;
  justify-content: left;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const StyledLinkSocialItem = styled.a`
  padding-right: 10px;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;

  :hover {
    color: #E6007F;
  }
`;
export const StyledLabel = styled.span`
 position: absolute;
 top: 20px;
 left: 20px;
 background: #E6007F;
 padding: 3px 20px;
 border-radius: 5px;
 color: #fff;
`;