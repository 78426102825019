import { StyledTextarea, StyledRequired, StyledInvalidData } from "./Partials";
import React from "react";

interface TextAreaProps {
  defaultValue: any;
  name: string;
  required: boolean;
  changeOptions: Function;
  _uid: string;
  type: string;
  isValid: boolean;
  maxLength?: number;
}

export const TextArea: React.FC<TextAreaProps> =
  ({ defaultValue, name, required, changeOptions, _uid, type, isValid, maxLength }) => {
    const pattern = "^[^()/><{}~;]*$";
    const regex = new RegExp(pattern);

    return (
      <>
        {isValid === false &&
          (defaultValue.length > 0 ? (
            <StyledInvalidData>Invalid data entry</StyledInvalidData>
          ) : (
            <StyledRequired>This is required</StyledRequired>
          ))}
        {maxLength && defaultValue.length >= maxLength && (
          <StyledInvalidData>The max length is {maxLength} characters</StyledInvalidData>
        )}
        <StyledTextarea
          defaultValue={defaultValue}
          id={_uid}
          name={name}
          required={required}
          onChange={($event) => {
            const { value, validity } = $event.target
            // Check if maxLength condition is true and set valid to false
            const isMaxLengthExceeded = maxLength && $event.target.value.length >= maxLength;
            const updatedValid = isMaxLengthExceeded ? false : validity.valid;

            changeOptions($event, name, _uid, type, updatedValid && regex.test(value));
          }}
        />
      </>
    );
  };