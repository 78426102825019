import styled, { css } from "styled-components";

export interface IPaginationContentProps {
  small?: boolean;
}

export const StyledPaginationContent = styled.div<IPaginationContentProps>`
  ${(props) =>
    props.small
      ? css`
          flex: 1 1 auto;
          font-weight: 400;
          text-align: center;
        `
      : css`
          align-items: center;
          display: flex;
          flex: 1 1 auto;
          flex-wrap: wrap;
          font-weight: 400;
          justify-content: center;
          text-align: center;
        `}
`;
